import { HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import qs from "query-string";
import React, { Component, createRef } from "react";
import {
  BrowserView,
  MobileOnlyView,
  TabletView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import ReactTimeout from "react-timeout";
import { Dimmer, Loader } from "semantic-ui-react";
import "firebase/messaging";

import { config } from "../../config";
import AuthContext from "../../contexts/AuthContext";
import { InstallPromptProvider } from "../../contexts/InstallPromptContext";
import ModalMountContext from "../../contexts/ModalMountContext";
import NotificationContext from "../../contexts/NotificationContext";
import { UpdatePromptProvider } from "../../contexts/UpdatePromptContext";
import { Api } from "../../helpers/api";
import { DEBUG, createDebugLogger } from "../../helpers/debug";
import Enum from "../../helpers/enums";
import rpsHub from "../../helpers/hub";
import TopNavMaster from "../../pages/TopNavMaster/TopNavMaster";

import ExternalRedirect from "../ExternalRedirect/ExternalRedirect";
import Icon from "../Icon/Icon";
import InviteHandler from "../InviteHandler/InviteHandler";
import ModalLogout from "../Modal/ModalLogout/ModalLogout";
import ModalPostRegistration from "../Modal/ModalPostRegistration/ModalPostRegistration";
import ModalRules from "../Modal/ModalRules/ModalRules";
import QrSvg from "../QrSvg/QrSvg";
import TextButton from "../TextButton/TextButton";
import WebViewBrowserWarning from "../WebViewBrowserWarning/WebViewBrowserWarning";

import "./Theme.scss";
import "./App.scss";

const {
  log: hubLog,
  logGroup: hubLogGroup,
  logGroupEnd: hubLogGroupEnd,
} = createDebugLogger(Enum.DebugChannel.RPSHUB);

const { log: appLog } = createDebugLogger(Enum.DebugChannel.APP);

class App extends Component {
  static contextType = AuthContext;
  desktopContentRef = createRef();

  constructor(props) {
    super(props);

    this.authDomain = config.domains.getDomain("auth");

    this.unregisterAuthObserver = null;
    this.hubReconnectInterval = null;

    this.state = {
      apiOffline: false,

      prevIsAuthenticated: null,
      prevAccount: null,

      groupsLoaded: false,
      groups: [],
      currentGroup: null,
      currentGroupMembers: null,

      hub: null,
      hubStateListeners: {
        connected: [],
        reconnecting: [],
        disconnected: [],
        reconnected: [],
      },
      hubReconnecting: false,
      hubForcedDisconnect: false,
      hubReconnectSeconds: 10,

      notificationConfig: null,
      notificationsLoaded: false,

      webViewBrowser: null,

      modalPostRegistrationOpen: false,
      modalPostRegistrationKey: `post-registration-${new Date()}`,

      modalUnblockNotificationsOpen: false,
      modalUnblockNotificationsKey: `unblock-notifications-${new Date()}`,

      modalRulesOpen: false,
      modalRulesKey: `rules-${new Date()}`,

      modalLogoutOpen: false,
      modalLogoutKey: `logout-${new Date()}`,

      modalInstallAppEnableNotificationsOpen: false,
      modalInstallAppEnableNotificationsKey: `iaen-${new Date()}`,
    };

    this.handleLogout = this.handleLogout.bind(this);
    this.handleModalPostRegistrationOpen =
      this.handleModalPostRegistrationOpen.bind(this);
    this.handleModalPostRegistrationClose =
      this.handleModalPostRegistrationClose.bind(this);
    this.handleModalRulesOpen = this.handleModalRulesOpen.bind(this);
    this.handleModalRulesClose = this.handleModalRulesClose.bind(this);
    this.handleModalLogoutOpen = this.handleModalLogoutOpen.bind(this);
    this.handleModalLogoutClose = this.handleModalLogoutClose.bind(this);
    this.handleChangeUserName = this.handleChangeUserName.bind(this);
    this.handleChangeTagline = this.handleChangeTagline.bind(this);
    this.handleGroupClosed = this.handleGroupClosed.bind(this);
    this.initialiseHubConnection = this.initialiseHubConnection.bind(this);
    this.handleReceiveInitialGroupList =
      this.handleReceiveInitialGroupList.bind(this);
    this.handleReceiveAccountEvents =
      this.handleReceiveAccountEvents.bind(this);
    this.handleReceiveAccountNotification =
      this.handleReceiveAccountNotification.bind(this);
    this.handleGroupSubscribed = this.handleGroupSubscribed.bind(this);
    this.registerHubStateListener = this.registerHubStateListener.bind(this);
    this.clearHubStateListeners = this.clearHubStateListeners.bind(this);
    this.setCurrentGroup = this.setCurrentGroup.bind(this);
    this.handleDecisionParticipating =
      this.handleDecisionParticipating.bind(this);
    this.clearUnseenRepeatingForGroup =
      this.clearUnseenRepeatingForGroup.bind(this);
    this.handleLoadMoreGroups = this.handleLoadMoreGroups.bind(this);
    this.loadAccountNotifications = this.loadAccountNotifications.bind(this);
    this.clearAccountNotifications = this.clearAccountNotifications.bind(this);
  }

  componentDidMount() {
    /* User agent detection to determine if user is viewing from a Meta webview browser (links from insta/fb messenger) */
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let webViewBrowser = null;

    var fbIosWebViewPatterns = /FBIOS|FBAN.*iOS|Instagram.*CPU iPhone OS/;
    var fbAndroidWebViewPatterns = /FB_IAB|FB4A|Instagram.*Android/;

    if (fbIosWebViewPatterns.test(userAgent)) {
      webViewBrowser = "iOS";
    } else if (fbAndroidWebViewPatterns.test(userAgent)) {
      webViewBrowser = "Android";
    }

    this.setState({
      webViewBrowser: webViewBrowser,
    });

    // If this returns an error then the API is offline, set apiOffline state to true
    Api.getNotificationConfig((response) => {
      this.setState({
        notificationConfig: response.data.reduce((acc, item) => {
          acc[item.type] = item;
          return acc;
        }, {}),
      });
    }).catch(() => {
      this.setState({ apiOffline: true });
    });

    this.updateHtmlClass();
  }

  componentDidUpdate(prevProps, prevState) {
    const { authState, checkNotificationPermissions } = this.context;

    if (
      authState.isAuthenticated &&
      authState.isAuthenticated !== this.state.prevIsAuthenticated
    ) {
      appLog("Authenticated, initialising hub connection and notifications");
      checkNotificationPermissions();

      this.initialiseHubConnection(() => {});

      if (authState.account?.firstLogin) this.handleModalPostRegistrationOpen();

      this.setState({ prevIsAuthenticated: authState.isAuthenticated });
    }

    if (!prevState.hubReconnecting && this.state.hubReconnecting) {
      this.setState({ hubReconnectSeconds: 10 });

      if (this.hubReconnectInterval)
        this.props.clearInterval(this.hubReconnectInterval);

      this.hubReconnectInterval = this.props.setInterval(() => {
        this.setState((prevState) => {
          return {
            hubReconnectSeconds:
              prevState.hubReconnectSeconds <= 0 ?
                10
              : prevState.hubReconnectSeconds - 1,
          };
        });
      }, 1000);
    } else if (prevState.hubReconnecting && !this.state.hubReconnecting) {
      if (this.hubReconnectInterval)
        this.props.clearInterval(this.hubReconnectInterval);
    }

    //if (!_.isEqual(authState.account, this.state.prevAccount))
    //  this.setState({ prevAccount: {...authState.account} })
  }

  componentWillUnmount() {
    if (this.unregisterAuthObserver) this.unregisterAuthObserver();
  }

  updateHtmlClass() {
    const existingClasses = document.documentElement.className
      .split(" ")
      .filter((c) => !c.startsWith("device-"));
    let deviceClass = "";

    if (isMobile && !isTablet) {
      deviceClass = "device-mobile";
    } else if (isTablet) {
      deviceClass = "device-tablet";
    } else if (isBrowser) {
      deviceClass = "device-browser";
    }

    document.documentElement.className = [...existingClasses, deviceClass].join(
      " ",
    );
  }

  initialiseHubConnection(onSuccess, onFailure) {
    const component = this;

    const hubConnection = new HubConnectionBuilder()
      .withUrl(`${rpsHub.baseURL}/rps`, {
        credentials: "include",
      })
      .withAutomaticReconnect([0, 1000, 5000, 15000])
      .configureLogging(DEBUG.hubLogLevel)
      .build();

    hubConnection.on(
      "receiveInitialGroupList",
      this.handleReceiveInitialGroupList,
    );
    hubConnection.on("receiveAccountEvents", this.handleReceiveAccountEvents);
    hubConnection.on(
      "receiveNotification",
      this.handleReceiveAccountNotification,
    );

    hubConnection.on("DEBUG_ReceiveDump", (asm, gsm, msm, gasm, masm) => {
      hubLogGroup("DEBUG connections map");
      hubLog("accounts", asm);
      hubLog("groups", gsm);
      hubLog("matches", msm);
      hubLog("groupaccounts", gasm);
      hubLog("matchaccounts", masm);
      hubLogGroupEnd();
    });

    hubConnection.onreconnected(() => {
      hubLog("--Reconnected--");

      this.state.hubStateListeners.reconnected.map((x) => x());
      this.setState({ hubReconnecting: false });
    });

    hubConnection.onreconnecting(() => {
      hubLog("--Reconnecting--");

      this.setState({ hubReconnecting: true });
      this.state.hubStateListeners.reconnecting.map((x) => x());
    });

    hubConnection.onclose(() => {
      if (!component._dismounting && !this.state.hubForcedDisconnect)
        component.initialiseHubConnection();
      else {
        hubLog("--Disconnected--");
        this.setState({ hubDisconnected: true });
        this.state.hubStateListeners.disconnected.map((x) => x());
      }
    });

    hubConnection
      .start()
      .then(() => {
        hubLog("--Connected--");
        if (this.state.hubStateListeners.connected.map)
          this.state.hubStateListeners.connected.map((x) => x());

        if (onSuccess) onSuccess();
      })
      .catch(() => {
        hubLog(
          "--Disconnected-- (failed to connect initially)",
          hubConnection.state,
        );
        this.setState({ hubDisconnected: true });

        if (onFailure) onFailure();
      });

    this.setState({ hub: hubConnection });
  }

  registerHubStateListener(type, event) {
    const typeState = {
      connected: [HubConnectionState.Connected],
      disconnected: [HubConnectionState.Disconnected],
      reconnecting: [HubConnectionState.Reconnecting],
      reconnected: [],
    };

    if (this.state.hub && typeState[type].indexOf(this.state.hub.state) > -1)
      event();
    else
      this.setState((prevState) => {
        return {
          hubStateListeners: {
            ...prevState.hubStateListeners,
            [type]:
              prevState.hubStateListeners[type] ?
                [...prevState.hubStateListeners[type], event]
              : [event],
          },
        };
      });
  }

  clearHubStateListeners() {
    this.setState({
      hubStateListeners: {
        connected: [],
        disconnected: [],
        reconnecting: [],
        reconnected: [],
      },
    });
  }

  handleLogout() {
    this.handleModalLogoutClose();

    window.location = `${this.authDomain}/signout?redirect=//${window.location.host}`;
    //Api.logout().then(() => {
    //  window.location = "/login";
    //});
  }

  handleLoadMoreGroups() {
    if (
      !this.state.groups ||
      !this.state.groups._more ||
      !this.state.groups._more.more
    )
      return false;

    Api.getMoreGroups(this.state.groups._more.more).then((groupList) => {
      this.setState((prevState) => {
        return {
          groups: {
            ...prevState.groups,
            data: [...prevState.groups.data, ...groupList.data],
            _more: groupList._more,
          },
        };
      });

      if (
        !groupList ||
        !groupList.data ||
        !groupList._more ||
        !groupList._more.more
      )
        return false;

      return true;
    });
  }

  handleReceiveAccountNotification(notification) {
    hubLog("Received account notification", notification);

    this.context.handleReceiveAccountNotification(notification);

    if (window.location.pathname === "/notifications")
      Api.markAllNotificationsSeen();
  }

  handleReceiveInitialGroupList(groupList) {
    hubLog("Received initial group list", groupList);

    this.setState({ groupsLoaded: true, groups: groupList });
  }

  handleReceiveAccountEvents(accountEvents) {
    if (!this.state.groups) return;

    const { authState } = this.context;

    this.setState((prevState) => {
      let newGroups = this.state.groups.data ? [...this.state.groups.data] : [];

      hubLogGroup("Processing account events");
      hubLog(accountEvents);

      for (let i = 0; i < accountEvents.length; i++) {
        hubLog(
          `Processing account event ${accountEvents[i].typeSlug} for group id ${accountEvents[i].groupId}`,
          accountEvents[i].subject,
          "current group",
          this.state.currentGroup?.id,
        );

        //accountEvents[i].groupId = Number(accountEvents[i].groupId);
        //if (isNaN(accountEvents[i].groupId)) accountEvents[i].groupId = 0;

        if (accountEvents[i].groupId === 0) {
          //hubLog("GroupId is 0, please fix");
          continue;
        }

        if (accountEvents[i].typeSlug === "group-added") {
          newGroups.unshift({
            ...accountEvents[i].subject,
            amParticipating: true,
          });
          continue;
        }

        // Other events require a list item
        let groupIdx =
          accountEvents[i].groupId ?
            newGroups.findIndex((g) => g.id === accountEvents[i].groupId)
          : null;

        hubLog(`Processing account event for list item ${groupIdx}`);

        if (groupIdx <= -1) continue;

        if (
          accountEvents[i].typeSlug === "group-decision-update" &&
          accountEvents[i].subject.bumpToTop === true
        ) {
          hubLog(`Bumping group ${accountEvents[i].groupId} to top of list`);
          const bumped = newGroups.splice(groupIdx, 1)[0];
          newGroups.unshift(bumped);
          groupIdx = 0;
        }

        //hubLog(`Group found at position ${groupIdx}`);
        let gduTourn = accountEvents[i].subject;

        switch (accountEvents[i].typeSlug) {
          case "group-added": // must be a promo group join
            newGroups[groupIdx].amPlayer = true;
            break;
          case "group-new-activity":
            newGroups[groupIdx].newActivity =
              accountEvents[i].groupId !== this.state.subscribedGroup &&
              accountEvents[i].subject;
            break;
          case "group-removed":
            newGroups.splice(groupIdx, 1);
            break;
          case "group-account-count-updated":
            newGroups[groupIdx].playerCount = accountEvents[i].subject;
            break;
          case "group-name-updated":
            newGroups[groupIdx].name = accountEvents[i].subject.name;
            break;
          case "group-decision-update":
            if (gduTourn.startMode === Enum.TournamentStartMode.QUICK) {
              if (gduTourn.stake !== null)
                newGroups[groupIdx].activeDecisionStake = gduTourn.stake;

              if (gduTourn.stakeIsPositive !== null)
                newGroups[groupIdx].activeDecisionStakeIsPositive =
                  gduTourn.stakeIsPositive;

              if (gduTourn.creatorId !== null)
                newGroups[groupIdx].activeDecisionCreatorId =
                  gduTourn.creatorId;

              newGroups[groupIdx].amParticipating =
                (
                  newGroups[groupIdx].activeDecisionCreatorId ===
                    authState?.account?.id ||
                  gduTourn.joinerId === authState?.account?.id
                ) ?
                  true
                : gduTourn.leaverId === authState?.account?.id ? false
                : null;

              if (gduTourn.rsvpCount !== null)
                newGroups[groupIdx].rsvpCount = gduTourn.rsvpCount;

              if (gduTourn.startTime !== null)
                newGroups[groupIdx].activeDecisionStartTime =
                  gduTourn.startTime;

              if (gduTourn.lastFinishedTournament !== null)
                newGroups[groupIdx].lastFinishedTournament =
                  gduTourn.lastFinishedTournament;

              newGroups[groupIdx].activeDecisionGameMode = gduTourn.gameMode;
            }

            if (gduTourn.repeatingGameCount !== null)
              newGroups[groupIdx].repeatingGameCount =
                gduTourn.repeatingGameCount;
            break;
          default:
            continue;
        }
      }

      hubLogGroupEnd();
      return { groups: { ...prevState.groups, data: newGroups } };
    });
  }

  clearUnseenRepeatingForGroup(groupId) {
    this.setState((prevState) => {
      let newGroups = this.state.groups.data ? [...this.state.groups.data] : [];
      let groupIdx = newGroups.findIndex((g) => g.id === groupId);
      newGroups[groupIdx].unseenRepeatingCount = 0;

      return {
        groups: {
          ...prevState.groups,
          data: newGroups,
        },
      };
    });
  }

  handleDecisionParticipating(groupId, participating) {
    this.setState((prevState) => {
      let newGroups = this.state.groups.data ? [...this.state.groups.data] : [];
      let groupIdx = newGroups.findIndex((g) => g.id === groupId);
      newGroups[groupIdx].amParticipating = participating;

      return {
        groups: {
          ...prevState.groups,
          data: newGroups,
        },
      };
    });
  }

  handleGroupSubscribed(group) {
    const groupId = group?.id;

    this.setState((prevState) => {
      if (groupId === null) return;

      let newGroups = this.state.groups.data ? [...this.state.groups.data] : [];
      let groupIdx = newGroups.findIndex((g) => g.id === groupId);
      if (groupIdx <= -1) return;

      newGroups[groupIdx].newActivity = false;

      return {
        subscribedGroup: groupId,
        groups: { ...prevState.groups, data: newGroups },
      };
    });
  }

  handleModalPostRegistrationOpen() {
    this.setState({
      modalPostRegistrationKey: `post-registration-${new Date()}`,
      modalPostRegistrationOpen: true,
    });
  }

  handleModalPostRegistrationClose() {
    this.setState({
      modalPostRegistrationOpen: false,
    });
  }

  handleModalRulesOpen() {
    this.setState({
      modalRulesKey: `rules-${new Date()}`,
      modalRulesOpen: true,
    });
  }

  handleModalRulesClose() {
    this.setState({
      modalRulesOpen: false,
    });
  }

  handleModalLogoutOpen() {
    this.setState({
      modalLogoutKey: `logout-${new Date()}`,
      modalLogoutOpen: true,
    });
  }

  handleModalLogoutClose() {
    this.setState({
      modalLogoutOpen: false,
    });
  }

  handleChangeUserName(userName, onSuccess, onError) {
    Api.changeUserName(userName)
      .then((response) => {
        this.context.handleChangeUserName(userName);

        onSuccess && onSuccess(response);
      })
      .catch((error) => {
        onError && onError(error);
      });
  }

  handleChangeTagline(tagline, onSuccess, onError) {
    Api.updateUser({ tagline })
      .then((response) => {
        this.context.handleChangeTagline(tagline);

        onSuccess && onSuccess(response);
      })
      .catch((error) => {
        onError && onError(error);
      });
  }

  handleGroupClosed(id) {
    this.setState((prevState) => {
      return {
        groups: prevState.groups.splice(
          prevState.groups.findIndex((x) => x.id === id),
          1,
        ),
      };
    });
  }

  setCurrentGroup(group, groupMembers, callback) {
    this.setState(
      { currentGroup: group, currentGroupMembers: groupMembers },
      callback,
    );
  }

  loadAccountNotifications() {
    this.setState(() => {
      return {
        notificationsLoaded: false,
      };
    });

    Api.getAccountNotifications((response) => {
      this.context.setAccountNotifications(response);

      this.setState({
        notificationsLoaded: true,
      });

      Api.markAllNotificationsSeen();
    });
  }

  clearAccountNotifications() {
    Api.clearAccountNotifications(() => {
      this.context.clearAccountNotifications();
    });
  }

  render() {
    const {
      hub,
      hubReconnecting,
      hubDisconnected,
      hubReconnectSeconds,
      authLoading,
      groups,
      groupsLoaded,
      currentGroup,
      currentGroupMembers,
      history,
      notificationConfig,
      notificationsLoaded,
    } = this.state;

    const pageClass =
      "page-" +
      window.location.pathname
        .slice(1)
        .toLowerCase()
        .replace("/", "-")
        .replace(/[^0-9a-z\-_]/gi, "");

    const { authState } = this.context;

    let urlParams = qs.parse(window.location.search);
    let returnTo = urlParams?.return;

    const appContainer = (
      <UpdatePromptProvider>
        <InstallPromptProvider>
          <NotificationContext.Provider value={notificationConfig}>
            <Router>
              <div id="app" className={pageClass}>
                {notificationConfig && (
                  <Switch>
                    <Route exact path="/">
                      {authState.isAuthenticated && (
                        <>
                          {returnTo && <Redirect to={returnTo} />}
                          {!returnTo && <Redirect to="/groups" />}
                        </>
                      )}

                      {!authState.isAuthenticated && (
                        <ExternalRedirect to="/signin" />
                      )}
                      <Dimmer inverted active>
                        <Loader inverted active content="Loading..." />
                      </Dimmer>
                    </Route>

                    <Route
                      exact
                      path="/i/:code([a-zA-Z0-9]{8})"
                      render={(props) => (
                        <>
                          {authState.isAuthenticated ?
                            <InviteHandler
                              inviteCode={props.match.params.code}
                            />
                          : <ExternalRedirect
                              to={`/signin/?return=/i/${props.match.params.code}`}
                            />
                          }
                        </>
                      )}
                    />

                    <Route
                      render={(props) => (
                        <TopNavMaster
                          authLoading={authLoading}
                          hub={hub}
                          groups={groups}
                          groupsLoaded={groupsLoaded}
                          onLoadMoreGroups={this.handleLoadMoreGroups}
                          currentGroup={currentGroup}
                          currentGroupMembers={currentGroupMembers}
                          registerHubStateListener={
                            this.registerHubStateListener
                          }
                          clearHubStateListeners={this.clearHubStateListeners}
                          onModalRulesOpen={this.handleModalRulesOpen}
                          onModalRulesClose={this.handleModalRulesClose}
                          onGroupClosed={this.handleGroupClosed}
                          onGroupSubscribed={this.handleGroupSubscribed}
                          onLogin={(response, returnTo) =>
                            this.handleLogin(response, returnTo)
                          }
                          onLogout={this.handleModalLogoutOpen}
                          onDecisionParticipating={
                            this.handleDecisionParticipating
                          }
                          setCurrentGroup={this.setCurrentGroup}
                          history={history}
                          clearUnseenRepeatingForGroup={
                            this.clearUnseenRepeatingForGroup
                          }
                          onChangeUserName={this.handleChangeUserName}
                          onChangeTagline={this.handleChangeTagline}
                          notificationsLoaded={notificationsLoaded}
                          loadAccountNotifications={
                            this.loadAccountNotifications
                          }
                          clearAccountNotifications={
                            this.clearAccountNotifications
                          }
                          {...props}
                        />
                      )}
                    />
                  </Switch>
                )}

                {hubReconnecting && (
                  <div id="hub-reconnecting">
                    <Icon className="fas fa-spin fa-spinner-third"></Icon>{" "}
                    Reconnecting ({hubReconnectSeconds}s)
                  </div>
                )}

                <Dimmer active={hubDisconnected} className="error-dimmer">
                  <Icon className="fal fa-3x fa-exclamation-triangle" />
                  <br />
                  <br />
                  We're having trouble reaching the server.
                  <br />
                  <br />
                  <TextButton onClick={() => window.location.reload(true)}>
                    Tap here to reload and try again.
                  </TextButton>
                </Dimmer>

                <Dimmer active={this.state.apiOffline} className="error-dimmer">
                  <Icon className="fal fa-3x fa-exclamation-triangle" />
                  <br />
                  <br />
                  We're having trouble reaching the server.
                  <br />
                  <br />
                  <TextButton onClick={() => window.location.reload(true)}>
                    Tap here to reload and try again.
                  </TextButton>
                </Dimmer>
              </div>

              <ModalPostRegistration
                key={this.state.modalPostRegistrationKey}
                open={this.state.modalPostRegistrationOpen}
                close={this.handleModalPostRegistrationClose}
                onChangeUserName={this.handleChangeUserName}
              />

              <ModalRules
                key={"rules-" + this.state.modalRulesKey}
                open={this.state.modalRulesOpen}
                close={this.handleModalRulesClose}
              />

              <ModalLogout
                key={"logout-" + this.state.modalLogoutKey}
                onLogout={this.handleLogout}
                open={this.state.modalLogoutOpen}
                close={this.handleModalLogoutClose}
              />
            </Router>
            <WebViewBrowserWarning
              platform={this.state.webViewBrowser}
            ></WebViewBrowserWarning>
          </NotificationContext.Provider>
        </InstallPromptProvider>
      </UpdatePromptProvider>
    );

    const notMobile = (
      <div id="desktop-frame">
        <div id="desktop-info">
          <h1>
            Play against your friends to decide who receives a prize or penalty!
          </h1>
          <h4>
            randomko.app is built for mobile
            <br />
            Play on your device for the best experience
          </h4>
          <div className="qr-code">
            <QrSvg />
          </div>
          <div className="rko-logo">
            <b>randomKO</b>.app <i>beta</i>
          </div>
          <div className="rko-beta">
            <TextButton
              onClick={() => {
                window.location.href =
                  "mailto:feedback@randomko.app?subject=randomKO beta feedback";
              }}
            >
              <Icon className="fas fa-envelope fa-fw"></Icon> We'd love your
              feedback while we're in beta!
            </TextButton>
          </div>
          <div className="desktop-arrow">
            Play it here if you can't play on mobile{" "}
            <Icon className="far fa-arrow-right fa-fw"></Icon>
            {/* <Image src="/images/arrow.png" /> */}
          </div>
        </div>
        <div id="desktop-content" ref={this.desktopContentRef}>
          <img src="/images/mobile-border.png" alt="mobile frame" />
          <div id="desktop-content-inner">
            <ModalMountContext.Provider value={this.desktopContentRef}>
              {appContainer}
            </ModalMountContext.Provider>
          </div>
        </div>
      </div>
    );

    return (
      <>
        <MobileOnlyView renderWithFragment={true}>
          <ModalMountContext.Provider value={null}>
            {appContainer}
          </ModalMountContext.Provider>
        </MobileOnlyView>
        <BrowserView renderWithFragment={true}>{notMobile}</BrowserView>
        <TabletView renderWithFragment={true}>{notMobile}</TabletView>
      </>
    );
  }
}

export default ReactTimeout(App);
