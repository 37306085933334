import moment from "moment";
import React from "react";
import Countdown from "react-countdown";

import EnableNotificationsPromptButton from "../../../EnableNotificationsPrompt/EnableNotificationsPromptButton";
import Icon from "../../../Icon/Icon";
import Plural from "../../../Plural/Plural";

import "./Timer.scss";

const Timer = ({
  endTime,
  labelled,
  desc,
  large,
  showNotificationBell,
  completedMessage,
}) => {
  const rawTime = moment.isMoment(endTime) ? endTime.toDate() : endTime;
  const momentTime = moment(endTime);

  const showDays = momentTime.diff(moment(), "days") >= 1;

  const pad = (length, input) => String(input).padStart(length, "0");

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    const padLength = labelled ? 2 : 1;

    if (completed) {
      return (
        <>
          <div>
            <span className="timer-completed">
              <Icon className="far fa-spin fa-spinner-third"></Icon>
            </span>
          </div>
          {completedMessage && (
            <div className="description">{completedMessage}</div>
          )}
        </>
      );
    }

    return (
      <>
        <div>
          {showDays && days >= 1 && (
            <>
              <div>
                <span className="value">
                  {pad(padLength, days)}
                  {!labelled ? "d " : ""}
                </span>
                {labelled && (
                  <span className="label">
                    <Plural base="day" value={days}></Plural>
                  </span>
                )}
              </div>
              {labelled && (
                <div className="colon">
                  <span className="value">:</span>
                  <span className="label">&nbsp;</span>
                </div>
              )}
            </>
          )}
          {(days >= 1 || hours >= 1) && (
            <>
              <div>
                <span className="value">
                  {pad(padLength, hours + (showDays ? 0 : days * 24))}
                  {!labelled ? "h " : ""}
                </span>
                {labelled && (
                  <span className="label">
                    <Plural base="hr" value={hours}></Plural>
                  </span>
                )}
              </div>
              {labelled && (
                <div className="colon">
                  <span className="value">:</span>
                  <span className="label">&nbsp;</span>
                </div>
              )}
            </>
          )}
          <div>
            <span className="value">
              {pad(padLength, minutes)}
              {!labelled ? "m " : ""}
            </span>
            {labelled && (
              <span className="label">
                <Plural base="min" value={minutes}></Plural>
              </span>
            )}
          </div>
          {labelled && (
            <div className="colon">
              <span className="value">:</span>
              <span className="label">&nbsp;</span>
            </div>
          )}
          <div>
            <span className="value">
              {pad(padLength, seconds)}
              {!labelled ? "s" : ""}
            </span>
            {labelled && (
              <span className="label">
                <Plural base="sec" value={seconds}></Plural>
              </span>
            )}
          </div>
        </div>
        <div className="description">
          <span>{desc}</span>
        </div>
      </>
    );
  };

  return (
    <div
      className={`component-timer ${
        labelled ? "component-timer-labelled" : "component-timer-inline"
      } ${large === true ? "component-timer-large" : ""}`}
    >
      <div className="countdown">
        <Countdown
          key={rawTime}
          date={rawTime}
          daysInHours={!showDays}
          renderer={renderer}
        />
      </div>
      {showNotificationBell && (
        <EnableNotificationsPromptButton
          iconOnly={true}
          hideWhenEnabled={true}
        />
      )}
    </div>
  );
};

export default Timer;
