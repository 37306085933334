import TWEEN from "@tweenjs/tween.js";
import moment from "moment";
import * as PIXI from "pixi.js-legacy";
import React, { Component } from "react";

import BoardSide from "./BoardSide";
import MoveHistory from "./MoveHistory/MoveHistory";
import MoveSlot from "./MoveSlot";
import MoveToken from "./MoveToken";
import Nameplate from "./Nameplate/Nameplate";

import { config } from "../../../config";
import Enum from "../../../helpers/enums";

import "./Board.scss";

// Just awful
class Board extends Component {
  constructor(props) {
    super(props);

    this.BOARD_ANIMATIONS = {
      "move-made": this.onMoveMade.bind(this),
      "game-finished": this.onGameFinished.bind(this),
      "tournament-finished": this.onTournamentFinished.bind(this),
    };

    this.RESULT_STRINGS = {
      [Enum.ResultType.DRAW]: "draw",
      [Enum.ResultType.WIN]: "win",
      [Enum.ResultType.LOSS]: "lose",
    };

    const { game, actor, opponents } = this.props;

    this.onFocus = () => {
      this.cancelWaves();
      this.startWaves();
    };

    this.onBlur = () => {
      this.cancelWaves();
    };

    this.onResize = () => {
      if (this.resizeTimeout) clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        // todo resize board
      }, 50);
    };
    this.resizeTimeout = null;

    this.boardCanvas = null;
    this.boardComponent = null;
    this.app = null;
    this.appWidth = this.appHeight = 0;
    this.is3KO = opponents.length === 2;

    this.sideUp = {
      move: game.moves.find((m) => m.accountId === opponents[0].accountId),
    };

    this.sideDn = {
      move: game.moves.find((m) => m.accountId === actor.accountId),
    };

    this.sideTp = {
      move:
        this.is3KO ?
          game.moves.find((m) => m.accountId === opponents[1].accountId)
        : null,
    };

    //console.log("BOARD SIDE DATA", this.sideUp, this.sideDn);

    this.generateAnimationDataFor = this.generateAnimationDataFor.bind(this);
    this.createApp = this.createApp.bind(this);
    this.createEvents = this.createEvents.bind(this);
    this.forEachSide = this.forEachSide.bind(this);
    this.sideY = this.sideY.bind(this);
    this.queueBoardMessage = this.queueBoardMessage.bind(this);
    this.handleBoardMessageDismiss = this.handleBoardMessageDismiss.bind(this);
    this.handlePixiClick = this.handlePixiClick.bind(this);
    this.handleDomClick = this.handleDomClick.bind(this);

    this.timesUpInterval = null;

    this.state = {
      boardMessages: [],
      boardMessageId: 0,
      timesUp: false,
      moveHistory: {},
    };
  }

  generateAnimationDataFor(game, accountId) {
    if (!game) return null;

    var amnData = {
      gameAccount: game.gameAccounts.find((ga) => ga.accountId === accountId),
      move: game.moves.find((m) => m.accountId === accountId),
      isTotalForfeit: game.isTotalForfeit,
      isDraw: game.isDraw,
    };

    amnData.noMove = !amnData.move;
    amnData.isForfeit = amnData.move && amnData.move.moveType === 0;
    amnData.resultStr = this.RESULT_STRINGS[amnData.gameAccount.result];

    return amnData;
  }

  componentDidMount() {
    const { group, tournament, round, actor, opponents, match, getNextRound } =
      this.props;

    let initMsg = "";

    const finalMovesDue = round.state === Enum.RoundState.ENDING;

    const myScore = match.matchAccounts.find(
      (x) => x.accountId === actor.accountId,
    ).score;
    const theirScore = match.matchAccounts.find(
      (x) => x.accountId === opponents[0].accountId,
    ).score;

    const im1away = myScore + 1 === tournament.firstToGames;
    const theyre1away = theirScore + 1 === tournament.firstToGames;
    const both1away = im1away && theyre1away;
    const any1away = im1away || theyre1away;

    //console.log(im1away, theyre1away, both1away, any1away);

    const nextRound = getNextRound(round.roundNumber);

    const mySemiFinalResultDecidesTournament =
      round.playersCount === 3 && !match.vsAi && round.playersRemaining === 2;

    // This if statement not used (was for promo tournaments with round ending times)
    if (finalMovesDue) {
      initMsg = "It's the final rock paper scissors game of the round.\r\n\r\n";
      if (myScore === theirScore)
        initMsg += `The ${
          tournament.stakeIsPositive ?
            "winner advances to " + nextRound.progwArticle
          : "loser moves one round closer to the penalty"
        }!`;
      else if (myScore === 1)
        initMsg += `Win to guarantee your ${
          tournament.stakeIsPositive ?
            "spot in " + nextRound.progwArticle
          : "safety from the penalty"
        }!`;
      else
        initMsg += `Win to make it 1 - 1.\r\n\r\nA random player will be selected to ${
          tournament.stakeIsPositive ?
            "advance to the next round"
          : "move one round closer to the penalty"
        }.`;
    } else if (any1away) {
      if (!!this.sideDn.move && !this.sideUp.move) {
        initMsg = `Waiting for ${this.props.opponents[0].accountUserName} to move`;
      } else {
        initMsg =
          both1away ?
            `${tournament.stakeIsPositive ? "Winner" : "Loser"} ${
              round.isFinalRound || mySemiFinalResultDecidesTournament ?
                tournament.stakeIsPositive ?
                  "receives the prize"
                : "receives the penalty"
              : tournament.stakeIsPositive ?
                `advances to ${nextRound.progwArticle}!`
              : "moves one round closer to penalty"
            }`
          : im1away ?
            `Win ${
              round.isFinalRound || mySemiFinalResultDecidesTournament ?
                `${
                  tournament.stakeIsPositive ?
                    "move to\r\nreceive the prize"
                  : `move and you're\r\nsafe from the penalty`
                }!`
              : tournament.stakeIsPositive ?
                `move\r\nto advance to ${nextRound.progwArticle}!`
              : "and you'll be\r\nsafe from the penalty"
            }`
          : theyre1away ?
            `Lose ${
              round.isFinalRound || mySemiFinalResultDecidesTournament ?
                `${
                  tournament.stakeIsPositive ?
                    "move and\r\nmiss out on the prize"
                  : `move and you\r\nreceive the penalty`
                }!`
              : tournament.stakeIsPositive ? `move and\r\nyou'll be knocked out`
              : "and you'll be\r\nsafe from the penalty"
            }`
          : ``;
      }
    } else {
      if (!this.sideDn.move) {
        initMsg = this.sideUp.move ? "Make your move!" : "Make your move!";
      } else {
        initMsg =
          group.isSolo ? "" : (
            `Waiting for ${
              this.is3KO ? "your opponents" : (
                opponents[0].accountUserName + " to move"
              )
            }`
          );
      }
    }

    this.createApp(
      initMsg,
      !!this.sideDn.move,
      actor.accountId,
      opponents[0].accountId,
    );

    setTimeout(() => {
      this.showMoveTokens(this.sideDn, !this.sideDn.move);
      this.showMoveTokens(this.sideUp, !this.sideUp.move);
      this.showMoveTokens(this.sideTp, !this.sideTp.move);
    }, 1000);

    if (!this.props.group.isSolo) this.startTimesUpChecker();

    this.createEvents();

    if (
      this.props.rpsEventCurrent?.typeSlug === "game-finished" &&
      this.sideUp.move &&
      this.sideDn.move
    )
      this.BOARD_ANIMATIONS[this.props.rpsEventCurrent.typeSlug](
        this.props.rpsEventCurrent.subject,
      );
  }

  componentDidUpdate(prevProps) {
    // The board listens to the currently playing RpsEvent within GroupPage
    // and updates its state and plays animations based on that event.

    const thisEvent = this.props.rpsEventCurrent;
    const prevEvent = prevProps.rpsEventCurrent;

    if (
      thisEvent &&
      Object.prototype.hasOwnProperty.call(
        this.BOARD_ANIMATIONS,
        thisEvent.typeSlug,
      ) &&
      (!prevEvent || (prevEvent && prevEvent.typeSlug !== thisEvent.typeSlug))
    ) {
      this.BOARD_ANIMATIONS[this.props.rpsEventCurrent.typeSlug](
        this.props.rpsEventCurrent.subject,
      );
    }

    const { game } = this.props;

    // If a new game starts, reset the board
    if (prevProps.game && game && prevProps.game.id !== game.id)
      this.newGame(prevProps.game);
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.onFocus);
    window.removeEventListener("blur", this.onBlur);
    window.removeEventListener("resize", this.onResize);
    if (this.resizeTimeout) clearTimeout(this.resizeTimeout);
    if (this.timesUpInterval) clearInterval(this.timesUpInterval);

    this.app.stop();
  }

  startTimesUpChecker() {
    if (this.timesUpInterval) {
      clearInterval(this.timesUpInterval);
    }

    this.setState({ timesUp: false });
    const { game } = this.props;

    if (!game || !game.endTime) return;

    this.timesUpInterval = setInterval(() => {
      if (moment().isSameOrAfter(game.endTime)) {
        this.setState({ timesUp: true });
        this.onGameTimeOut();
        clearInterval(this.timesUpInterval); // Stop checking once time is up
      }
    }, 1000);
  }

  createApp(initMsg, iMoved, accountId, opponentId) {
    //console.log("CREATING PIXI APP");
    const { opponents } = this.props;

    this.appWidth = this.boardCanvas.offsetWidth;
    this.appHeight = this.boardCanvas.offsetHeight;

    const devicePixelRatio = window.devicePixelRatio || 1;

    this.app = new PIXI.Application({
      transparent: true,
      width: this.appWidth,
      height: this.appHeight,
      sharedLoader: true,
      sharedTicker: true,
      sortableChildren: true,
      resolution: devicePixelRatio,
      autoDensity: true,
    });

    this.app.stage.interactive = true;
    this.app.stage.on("pointerdown", this.handlePixiClick);

    this.smallBoard = this.appHeight < 600;

    this.boardCanvas.appendChild(this.app.view);

    // Board backgrounds
    this.sideDn.side = new BoardSide(this, false, false, false, accountId);
    this.sideUp.side = new BoardSide(this, true, this.is3KO, false, opponentId);
    if (this.is3KO) this.sideTp.side = new BoardSide(this, true, true, true);

    // If side up and side down both have moves, the game is already finish and we're probably loading in to display a game result
    // so animate both tokens fighting

    // Move slots
    this.sideUp.slot = new MoveSlot(
      this,
      true,
      this.sideUp.move && !this.sideDn.move,
      this.sideDn.move,
      this.is3KO,
    );
    // if (this.is3KO)
    //   this.sideTp.slot = new MoveSlot(
    //     this,
    //     true,
    //     this.sideTp.move,
    //     this.sideDn.move,
    //     true,
    //     true
    //   );
    this.sideDn.slot = new MoveSlot(this, false, false, this.sideDn.move);

    // Move tokens
    this.sideUp.tokens = [1, 2, 3].map(
      (type, idx) =>
        new MoveToken(
          this,
          type,
          idx,
          true,
          this.sideUp.move,
          this.is3KO,
          false,
        ),
    );

    // if (this.is3KO)
    //   this.sideTp.tokens = [1, 2, 3].map(
    //     (type, idx) =>
    //       new MoveToken(this, type, idx, true, this.sideTp.move, true, true),
    //   );

    this.sideDn.tokens = [1, 2, 3].map(
      (type, idx) => new MoveToken(this, type, idx, false, this.sideDn.move),
    );

    // Solo anon result msg
    const saStyle = new PIXI.TextStyle(config.board.textConfig.soloAnonResult);

    this.soloAnonText = new PIXI.Text("", saStyle);
    this.soloAnonText.roundPixels = true;
    this.soloAnonText.anchor.set(0.5);
    this.soloAnonText.alpha = 0;
    this.soloAnonText.x = this.appWidth / 2;
    this.soloAnonText.y = this.appHeight - 100 - this.smB(25, 50);

    this.app.stage.addChild(this.soloAnonText);

    // Init msg
    const style = new PIXI.TextStyle(config.board.textConfig.messageSm);

    this.initMsgText = new PIXI.Text(initMsg, style);
    this.initMsgText.roundPixels = true;
    this.initMsgText.anchor.set(0.5);
    this.initMsgText.alpha = 1;
    this.initMsgText.x = this.appWidth / 2;
    this.initMsgText.y = this.appHeight - this.smB(25, 50);

    this.app.stage.addChild(this.initMsgText);

    // Locked in msg
    const liStyle = new PIXI.TextStyle(config.board.textConfig.lockedIn);

    this.lockedInText = new PIXI.Text("Move locked in", liStyle);
    this.lockedInText.roundPixels = true;
    this.lockedInText.anchor.set(0.5);
    this.lockedInText.alpha = iMoved ? 1 : 0;
    this.lockedInText.x = this.appWidth / 2;
    this.lockedInText.y = this.appHeight / 2 + 95;

    this.app.stage.addChild(this.lockedInText);

    // Locked in msg
    this.lockedIn2Text = new PIXI.Text(
      opponents[0].accountUserName + "'s move locked in",
      liStyle,
    );
    this.lockedIn2Text.roundPixels = true;
    this.lockedIn2Text.anchor.set(0.5);
    this.lockedIn2Text.alpha = this.sideUp.move ? 1 : 0;
    this.lockedIn2Text.x = this.appWidth / 2;
    this.lockedIn2Text.y = this.appHeight / 2 - 95;

    this.app.stage.addChild(this.lockedIn2Text);
  }

  smB(sm, lg) {
    return this.smallBoard ? sm : lg;
  }

  newGame() {
    // Players will be the same, so board sides won't change
    // Simply reset move tokens and slots
    //const { actor, opponents } = this.props;

    // Update
    /*
    this.sideDn.history.newGame(
      this.getMoveHistoryFromGame(prevGame, actor.accountId)
    );
    this.sideUp.history.newGame(
      this.getMoveHistoryFromGame(prevGame, opponents[0].accountId)
    );

    if (this.is3KO)
      this.sideTp.history.newGame(
        this.getMoveHistoryFromGame(prevGame, opponents[1].accountId)
      );
    */

    // Reset the tokens and slot for each side
    this.forEachSide((s) => {
      s.tokens.map((t) => t.newGame());
      s.slot.newGame();
    });

    // Reset the wave animation
    this.startWaves();

    this.startTimesUpChecker();
  }

  createEvents() {
    window.addEventListener("focus", this.onFocus);
    window.addEventListener("blur", this.onBlur);
    window.addEventListener("resize", this.onResize);

    this.app.ticker.add(() => {
      if (!window.focus) return;

      TWEEN.update();

      this.forEachSide((side) => {
        side.tokens.forEach((mt) => mt.tick());
        side.slot.tick();

        //if (side.history) side.history.tick();
        if (side.pips) side.pips.tick();
      });
    });
  }

  // Getters for each side
  getComponent(isOpponent, isThirdPlayer, component) {
    return (
      isOpponent ?
        isThirdPlayer ? this.sideTp
        : this.sideUp
      : this.sideDn)[component];
  }

  forEachSide(func) {
    if (this.is3KO) [this.sideUp, this.sideDn, this.sideTp].forEach(func);
    else [this.sideUp, this.sideDn].forEach(func);
  }

  // Actions
  showMoveTokens(side, animate) {
    if (!side || !side.tokens) return;

    side.tokens.forEach((mt, idx) => {
      mt.visible = true;
      mt.enterBoard(animate, idx * 200);
    });
  }

  queueBoardMessage(text) {
    this.setState((prevState) => {
      setTimeout(() => {
        this.handleBoardMessageDismiss(prevState.boardMessageId);
      }, config.board.toast.time);

      return {
        boardMessages: [
          {
            id: prevState.boardMessageId,
            text: text,
          },
          ...prevState.boardMessages,
        ],
        boardMessageId: prevState.boardMessageId + 1,
      };
    });
  }

  handleBoardMessageDismiss(id) {
    this.setState((prevState) => {
      var idx = prevState.boardMessages.findIndex((x) => x.id === id);
      if (idx < 0) return {};

      let updated = [...prevState.boardMessages];
      updated.splice(idx, 1);

      return {
        boardMessages: updated,
      };
    });
  }

  startWaves() {
    this.sideDn.tokens.forEach((mt, idx) => mt.startWaveTimer(idx * 100));
  }

  cancelWaves() {
    this.sideDn.tokens.forEach((mt) => mt.cancelWaveTimer());
  }

  //Events
  handleMoveLockedIn(type) {
    //const { group } = this.props;

    if (!this.props.group.isSoloAnon) this.lockedInText.alpha = 1;

    this.props.onMove(type);
    this.lockBoard();

    if (!this.sideUp.move || (this.is3KO && !this.sideTp.move))
      this.initMsgText.text =
        this.props.group.isSolo ?
          ""
        : `Waiting for ${
            this.is3KO ? "your opponents" : (
              this.props.opponents[0].accountUserName
            )
          } to move`;
    else this.initMsgText.alpha = 0;

    this.sideDn.slot.onMoveLockedIn();
    //if (!this.sideUp.move) this.sideUp.slot.showWaiting();
    //if (this.is3KO && !this.sideTp.move) this.sideTp.slot.showWaiting();

    this.sideDn.tokens.forEach((mt) => mt.type !== type && mt.hide());
  }

  lockBoard() {
    this.sideDn.tokens.forEach((mt) => mt.disable());
    this.cancelWaves();
  }

  onMoveMade(move) {
    const { match, game, actor, opponents } = this.props;

    if (!game || !move || move.matchId !== match.id || move.gameId !== game.id)
      return;

    // Main opponent move
    if (move.accountId === opponents[0].accountId) {
      this.sideUp.move = move;
      this.lockedIn2Text.alpha = 1;
      //this.sideUp.slot.hideWaiting();
      if (!this.sideDn.move) this.sideUp.slot.showHiddenMove();

      this.sideUp.tokens.forEach((mt) => mt.hide());
      //console.log("side up move locked in", move);

      //if (!this.sideDn.move)
      //  this.queueBoardMessage(`${opponents[0].accountUserName} has moved.`);
    } else if (this.is3KO && move.accountId === opponents[1].accountId) {
      this.sideTp.move = move;
      //this.sideTp.slot.hideWaiting();
      this.sideTp.slot.showHiddenMove();

      //if (!this.sideDn.move)
      //  this.queueBoardMessage(`${opponents[0].accountUserName} has moved.`);
    } else if (move.accountId === actor.accountId) {
      this.sideDn.move = move;
      //console.log("side down move locked in", move);
      //if (!this.sideUp.move || (this.is3KO && !this.sideTp.move))
      //  this.queueBoardMessage(
      //    `Move made, waiting for opponent${this.is3KO ? "s" : ""}.`
      //  );
    }
  }

  onGameTimeOut() {
    this.lockBoard();
  }

  onGameFinished(game) {
    const { actor, opponents } = this.props;

    this.lockBoard();

    this.sideUp.amn = this.generateAnimationDataFor(
      game,
      opponents[0].accountId,
    );

    if (this.is3KO)
      this.sideTp.amn = this.generateAnimationDataFor(
        game,
        opponents[1].accountId,
      );

    this.sideDn.amn = this.generateAnimationDataFor(game, actor.accountId);

    this.sideUp.slot.hideHiddenMove();
    this.sideUp.slot.hideWaiting();

    if (this.is3KO) {
      this.sideTp.slot.hideHiddenMove();
      this.sideTp.slot.hideWaiting();
    }

    // Up side

    if (this.sideUp.amn.isForfeit) this.sideUp.slot.showForfeitMove();
    else if (!this.sideUp.amn.noMove) {
      this.sideUp.tokens[this.sideUp.amn.move.moveType - 1].moveToSlot();

      setTimeout(() => {
        this.sideUp.tokens[this.sideUp.amn.move.moveType - 1].fight(
          this.sideUp.amn.resultStr,
          () => {
            /*this.sideUp.history.prepareToAddHistory();
            this.sideUp.tokens[
              this.sideUp.amn.move.moveType - 1
            ].moveToHistory();*/
          },
        );
      }, 500);
    }

    if (this.is3KO) {
      if (this.sideTp.amn.isForfeit) this.sideTp.slot.showForfeitMove();
      else if (!this.sideTp.amn.noMove) {
        this.sideTp.tokens[this.sideTp.amn.move.moveType - 1].moveToSlot();

        setTimeout(() => {
          this.sideTp.tokens[this.sideTp.amn.move.moveType - 1].fight(
            this.sideTp.amn.resultStr,
            () => {
              /*this.sideTp.history.prepareToAddHistory();
              this.sideTp.tokens[
                this.sideTp.amn.move.moveType - 1
              ].moveToHistory();*/
            },
          );
        }, 500);
      }
    }

    // Down side
    if (this.sideDn.amn.isForfeit) this.sideDn.slot.showForfeitMove();

    this.showResultText();

    if (this.sideDn.amn.noMove || this.sideDn.amn.isForfeit) {
      //
    } else {
      setTimeout(() => {
        this.sideDn.tokens[this.sideDn.amn.move.moveType - 1].fight(
          this.sideDn.amn.resultStr,
        );
      }, 500);
    }
  }

  showResultText() {
    if (!this.props.group.isSoloAnon) return;

    this.lockedInText.alpha = 0;
    this.initMsgText.alpha = 0;
    this.soloAnonText.alpha = 1;

    this.soloAnonText.text = this.getResultString(
      this.sideUp.amn,
      this.sideDn.amn,
    );
  }

  onTournamentFinished() {
    this.lockBoard();
  }

  // USED ONLY FOR ANONYMOUS SOLO GAMES VS BOT
  getResultString(upAmn, dnAmn) {
    let upType = upAmn.move ? upAmn.move.moveType : null;
    let dnType = dnAmn.move ? dnAmn.move.moveType : null;
    let result = "";

    if ((upType === 1 && dnType === 3) || (upType === 3 && dnType === 1))
      result = "Rock smashes scissors,";
    else if ((upType === 1 && dnType === 2) || (upType === 2 && dnType === 1))
      result = "Paper covers rock,";
    else if ((upType === 2 && dnType === 3) || (upType === 3 && dnType === 2))
      result = "Scissors cuts paper,";
    else if (upType === dnType && upType > 0) result = "It's a draw!";
    else if (upType === dnType && upType === 0) result = "You both forfeited!";
    else if (dnType === 0) result = "You forfeited!";
    else if (upType === 0) result = "Your opponent forfeited,";

    if (dnAmn.resultStr !== "draw" && dnType !== 0)
      result += ` you ${dnAmn.resultStr}!`;

    return result;
  }

  showDragTutorial() {}

  // Utilities
  sideY(isOpponent, y) {
    return isOpponent ? y : this.appHeight - y;
  }

  handlePixiClick = (event) => {
    if (event.target === this.app.stage) this.handleDomClick();
  };

  handleDomClick() {
    const event = new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    });

    this.boardComponent.dispatchEvent(event);
  }

  // Render
  render() {
    const { group, actor, opponents, match } = this.props;

    const scores = [
      match?.matchAccounts.find((x) => x.accountId === actor.accountId).score,
      match?.matchAccounts.find((x) => x.accountId === opponents[0].accountId)
        .score,
    ];

    if (this.is3KO)
      scores.push(
        match.matchAccounts.find((x) => x.accountId === opponents[1].accountId)
          .score,
      );

    return (
      <div
        className="component-board"
        ref={(el) => {
          this.boardComponent = el;
        }}
      >
        <div>
          <div
            id="board-canvas"
            ref={(el) => {
              this.boardCanvas = el;
            }}
          ></div>
          <div className="main-nameplates">
            <Nameplate
              group={group}
              player={actor}
              score={scores[0]}
              isOpponent={false}
              hideScore={
                this.props.tournament?.firstToGames === 1 ||
                this.props.group?.isSoloAnon
              }
              handleModalUserManageOpen={this.props.handleModalUserManageOpen}
            ></Nameplate>
            <div className="vs">vs</div>
            <Nameplate
              group={group}
              player={opponents[0]}
              score={scores[1]}
              isOpponent={true}
              hideScore={
                this.props.tournament?.firstToGames === 1 ||
                this.props.group?.isSoloAnon
              }
              handleModalUserManageOpen={this.props.handleModalUserManageOpen}
            ></Nameplate>
          </div>
          {this.props.moveHistory && (
            <>
              <div className="move-histories">
                <MoveHistory
                  moves={this.props.moveHistory[opponents[0].accountId]}
                  isOpponent={true}
                ></MoveHistory>
                <MoveHistory
                  moves={this.props.moveHistory[actor.accountId]}
                ></MoveHistory>
              </div>
            </>
          )}
          {this.is3KO && (
            <Nameplate
              group={group}
              player={opponents[1]}
              score={scores[2]}
              isOpponent={true}
              isThirdPlayer={true}
              hideScore={this.props.tournament?.firstToGames === 1}
              handleModalUserManageOpen={this.props.handleModalUserManageOpen}
            ></Nameplate>
          )}
        </div>
      </div>
    );
  }
}

export default Board;
