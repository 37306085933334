import React from "react";

import { minutesToTimeString } from "../../utilities";
import Icon from "../Icon/Icon";

import "./TournamentRulesSummary.scss";

const TournamentRulesSummary = ({
  firstToGames,
  stakeIsPositive,
  gameLengthMinutes,
  winnersChosen,
  promo,
  isFinalRound,
}) => (
  <div className="progress-tournament-rules">
    {firstToGames === 1 && (
      <div>
        <Icon className="far fa-trophy"></Icon> Win{" "}
        {stakeIsPositive ?
          <>
            {isFinalRound ?
              " to receive the prize"
            : " to advance to next round"}
          </>
        : " to be safe from the penalty"}
      </div>
    )}
    {firstToGames > 1 && (
      <div>
        <Icon className="far fa-trophy"></Icon> Win {firstToGames} moves
        {stakeIsPositive ?
          <>
            {!isFinalRound ?
              " to advance to next round"
            : " to receive the prize"}
          </>
        : " to be safe from the penalty"}
      </div>
    )}
    {gameLengthMinutes && (
      <div>
        <Icon className="far fa-clock"></Icon>
        {minutesToTimeString(gameLengthMinutes, !promo, !promo)} to make a move
      </div>
    )}
    {winnersChosen && !promo && (
      <div>
        <Icon className="far fa-user-check"></Icon> Choosing {winnersChosen}{" "}
        {stakeIsPositive ? "winner" : "player"}
        {winnersChosen > 1 ? "s" : ""}
      </div>
    )}
  </div>
);

export default TournamentRulesSummary;
