import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button } from "semantic-ui-react";
import _ from "underscore";

import DecisionResultsMembers from "./DecisionResultsMembers";
import { config } from "../../config";
import AuthContext from "../../contexts/AuthContext";
import Enum from "../../helpers/enums";
import Avatar from "../Avatar/Avatar";
import Icon from "../Icon/Icon";
import ModalStakeInfo from "../Modal/ModalStakeInfo/ModalStakeInfo";
import ModalUserManage from "../Modal/ModalUserManage/ModalUserManage";

import "./DecisionHistory.scss";

const DecisionResults = ({
  tournament,
  group,
  onDecisionReplay,
  loading,
  handleModalAlertOpen,
}) => {
  const { authState } = useContext(AuthContext);
  const [chosen, setChosen] = useState([]);
  const [allPlayers, setAllPlayers] = useState([]);

  const handleDecisionReplay = () => {
    onDecisionReplay(
      tournament.stake,
      tournament.prepareLengthMinutes,
      tournament.winnersToDraw,
    );
  };

  const [modalUserManageOpen, setModalUserManageOpen] = useState(false);
  const [modalUserManageKey, setModalUserManageKey] = useState(
    `user-manage-${new Date()}`,
  );
  const [modalUserManageId, setModalUserManageId] = useState(null);
  const [modalUserManageObj, setModalUserManageObj] = useState(null);

  const [modalStakeInfoOpen, setModalStakeInfoOpen] = useState(false);
  const [modalStakeInfoKey, setModalStakeInfoKey] = useState(new Date());

  const handleModalStakeInfoOpen = () => {
    setModalStakeInfoOpen(true);
    setModalStakeInfoKey(new Date());
  };

  const handleModalStakeInfoClose = () => {
    setModalStakeInfoOpen(false);
  };

  const handleModalUserManageOpen = (user) => {
    setModalUserManageKey(`group-name-${new Date()}`);
    setModalUserManageId(user.accountId);
    setModalUserManageObj(user);
    setModalUserManageOpen(true);
  };

  const handleModalUserManageClose = () => {
    setModalUserManageOpen(false);
  };

  // const amCreator = useMemo(() => {
  //   if (!tournament || !authState || !authState.account) return false;
  //   return tournament.creatorId === authState.account.id;
  // }, [tournament, authState]);

  useEffect(() => {
    if (!tournament) return;

    const chosenIds =
      tournament.theChosenOnes ?
        tournament.theChosenOnes.map((x) => x.accountId)
      : [];

    setChosen(
      _.filter(
        tournament.tournamentAccounts,
        (x) => chosenIds.indexOf(x?.accountId) > -1,
      ),
    );

    const meId = authState?.account.id;
    const sortedPlayers = _.filter(
      tournament.tournamentAccounts,
      (x) => x?.participating,
    ).sort((a, b) => {
      if (a.isBoss && !b.isBoss) {
        return -1;
      } else if (!a.isBoss && b.isBoss) {
        return 1;
      } else if (a.accountId === meId && b.accountId !== meId) {
        return -1;
      } else if (a.accountId !== meId && b.accountId === meId) {
        return 1;
      } else {
        return a.accountUserName.localeCompare(b.accountUserName);
      }
    });

    setAllPlayers(sortedPlayers);
  }, [authState?.account.id, tournament]);

  const componentClass = useMemo(() => {
    if (loading) return "";

    if (chosen?.length === 1) return "decision-results-big-winner";
    if (chosen?.length > 1) return "decision-results-no-big-winner";
  }, [chosen, loading]);

  return (
    <>
      <div className={`component-decision-results ${componentClass}`}>
        <div className="group-upper">
          {!loading && (
            <>
              <div className="decision-info decision-results">
                <div className="decision-results-header">
                  {tournament.gameMode === Enum.TournamentGameMode.RANDOM && (
                    <>
                      <h3 className="drh-game-mode">Random choice</h3>
                    </>
                  )}
                  {tournament.gameMode ===
                    Enum.TournamentGameMode.TOURNAMENTRPS && (
                    <>
                      <h3 className="drh-game-mode">Rock paper scissors</h3>
                    </>
                  )}

                  <h5 className="decision-results-time">
                    {tournament.startTimeString}

                    {tournament.startMode ===
                      Enum.TournamentStartMode.REPEATING && (
                      <span>
                        <br />
                        <Icon className="far fa-calendar-days"></Icon> This is a
                        repeating game
                      </span>
                    )}
                  </h5>

                  <h5
                    className="decision-results-played-for"
                    onClick={handleModalStakeInfoOpen}
                  >
                    {tournament.gameMode ===
                      Enum.TournamentGameMode.TOURNAMENTRPS && (
                      <>
                        {tournament.stakeIsPositive && <>Played for prize</>}
                        {!tournament.stakeIsPositive && <>Played for penalty</>}
                      </>
                    )}
                    {tournament.gameMode === Enum.TournamentGameMode.RANDOM && (
                      <span>Played for</span>
                    )}
                  </h5>

                  <p
                    className="decision-results-stake ellipsis-overflow"
                    onClick={handleModalStakeInfoOpen}
                  >
                    {tournament.stake}
                  </p>
                </div>

                {!chosen ||
                  (chosen.length < 2 && (
                    <div className="decision-results-chosen-section">
                      {tournament.state === Enum.TournamentState.CANCELLED && (
                        <>
                          <Icon className="far fa-times"></Icon>
                          {tournament.gameMode ===
                            Enum.TournamentGameMode.RANDOM && (
                            <>
                              <h3 className="text-red">Cancelled</h3>
                            </>
                          )}
                          {tournament.gameMode ===
                            Enum.TournamentGameMode.TOURNAMENTRPS && (
                            <>
                              <h3 className="text-red">Cancelled</h3>
                              {tournament.endReason ===
                                Enum.EndReason.GAME_EXPIRED && (
                                <h5>Players ran out of time</h5>
                              )}
                            </>
                          )}
                          {tournament.endReason ===
                            Enum.EndReason.COULDNT_START && (
                            <h5>Not enough players to start</h5>
                          )}
                        </>
                      )}
                      {chosen.length === 1 && (
                        <div className={`decision-results-winner`}>
                          <h4>Chosen</h4>
                          <div
                            onClick={() => handleModalUserManageOpen(chosen[0])}
                          >
                            <div className="decision-results-winner-avatar">
                              <Avatar user={chosen[0]?.accountId}></Avatar>
                            </div>
                            <h5>
                              {chosen[0]?.accountUserName}
                              {tournament.endReason ===
                                Enum.EndReason.SKIPPED_FINAL_DUE_TO_RKOBOT && (
                                <span
                                  className="skipped-final"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleModalAlertOpen(
                                      `Chosen because a player
                                  ${
                                    tournament.stakeIsPositive ? " lost to " : (
                                      " beat "
                                    )
                                  }
                                  RKObot`,
                                      tournament.stakeIsPositive ?
                                        config.messages.chosenBecauseRkoBot
                                          .positive
                                      : config.messages.chosenBecauseRkoBot
                                          .negative,
                                    );
                                  }}
                                >
                                  <Icon className="far fa-question-circle"></Icon>
                                </span>
                              )}
                              {tournament.endReason ===
                                Enum.EndReason
                                  .SKIPPED_FINAL_DUE_TO_FORFEITS && (
                                <span
                                  className="skipped-final"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleModalAlertOpen(
                                      `Chosen due to forfeits`,
                                      tournament.stakeIsPositive ?
                                        config.messages.chosenBecauseForfeits
                                          .positive
                                      : config.messages.chosenBecauseForfeits
                                          .negative,
                                    );
                                  }}
                                >
                                  <Icon className="far fa-question-circle"></Icon>
                                </span>
                              )}
                              {tournament.randomResult &&
                                tournament.gameMode ===
                                  Enum.TournamentGameMode.TOURNAMENTRPS && (
                                  <span
                                    className="skipped-final"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleModalAlertOpen(
                                        `Chosen at random`,
                                        tournament.stakeIsPositive ?
                                          config.messages
                                            .chosenRandomlyDueToFinalMatchDrawOrDoubleForfeit
                                            .positive
                                        : config.messages
                                            .chosenRandomlyDueToFinalMatchDrawOrDoubleForfeit
                                            .negative,
                                      );
                                    }}
                                  >
                                    <Icon className="far fa-question-circle"></Icon>
                                  </span>
                                )}
                            </h5>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
        {!loading && (
          <>
            <div className="group-lower">
              <div className="decision-results-chosen">
                <DecisionResultsMembers
                  currentTournament={tournament}
                  group={group}
                  chosen={chosen}
                  notChosen={allPlayers}
                  handleModalUserManageOpen={handleModalUserManageOpen}
                ></DecisionResultsMembers>
              </div>
              {tournament.startMode === Enum.TournamentStartMode.QUICK && (
                <>
                  <div className="action-panel">
                    {/* {tournament.gameMode ===
                    Enum.TournamentGameMode.INSTANTRPS && (
                    <Button
                      icon
                      className="decide-now"
                      onClick={handleModalViewTournamentOpen}
                    >
                      <Icon className="fas fa-code-fork fa-rotate-90"></Icon>{" "}
                      View tournament
                    </Button>
                  )} */}
                    <Button
                      icon
                      size="big"
                      primary
                      className="decide-now"
                      onClick={handleDecisionReplay}
                    >
                      <Icon className="fas fa-arrows-rotate"></Icon> Replay this
                      game
                    </Button>
                  </div>
                </>
              )}
            </div>
          </>
        )}

        <ModalUserManage
          key={modalUserManageKey}
          open={modalUserManageOpen}
          close={handleModalUserManageClose}
          group={group}
          userId={modalUserManageId}
          user={modalUserManageObj}
          currentTournament={tournament}
          hideParticipating={true}
        />
      </div>

      {tournament && (
        <ModalStakeInfo
          key={modalStakeInfoKey}
          open={modalStakeInfoOpen}
          close={handleModalStakeInfoClose}
          tournament={tournament}
        ></ModalStakeInfo>
      )}
    </>
  );
};

export default DecisionResults;
