import moment from "moment";
import queryString from "query-string";
import React from "react";
import Joyride from "react-joyride";
import ReactTimeout from "react-timeout";
import { Loader, Dimmer } from "semantic-ui-react";
import _ from "underscore";

import GroupLowerNoTournament from "./GroupPageComponents/GroupLower/GroupLowerNoTournament/GroupLowerNoTournament";
import GroupLowerQuick from "./GroupPageComponents/GroupLower/GroupLowerQuick/GroupLowerQuick";
import GroupLowerRepeating from "./GroupPageComponents/GroupLower/GroupLowerRepeating/GroupLowerRepeating";
import GroupLowerRpsTournament from "./GroupPageComponents/GroupLower/GroupLowerRpsTournament/GroupLowerRpsTournament";
import GroupUpper from "./GroupPageComponents/GroupUpper";
import TournamentSlideout from "./GroupPageComponents/TournamentSlideout";
import UserSlideout from "./GroupPageComponents/UserSlideout";
import ConfirmBox from "../../components/ConfirmBox/ConfirmBox";
import DecidingInterface from "../../components/DecidingInterface/DecidingInterface";
import DecisionHistory from "../../components/DecisionHistory/DecisionHistory";
import DecisionResults from "../../components/DecisionHistory/DecisionResults";
import DevTools from "../../components/DevTools/DevTools";
import { joyrideTours } from "../../components/JoyrideButton/JoyrideTours";
import ModalDecisionOptions from "../../components/Modal/ModalDecisionOptions/ModalDecisionOptions";
import ModalDecisionParticipants from "../../components/Modal/ModalDecisionParticipants/ModalDecisionParticipants";
import ModalGroupAlert from "../../components/Modal/ModalGroupAlert/ModalGroupAlert";
import ModalGroupOptions from "../../components/Modal/ModalGroupOptions/ModalGroupOptions";
import ModalInvite from "../../components/Modal/ModalInvite/ModalInvite";
import ModalRules from "../../components/Modal/ModalRules/ModalRules";
import ModalUserManage from "../../components/Modal/ModalUserManage/ModalUserManage";
import withAuth from "../../components/withAuth";
import { config } from "../../config";
import AuthContext from "../../contexts/AuthContext";
import { StakeInputRefContext } from "../../contexts/StakeInputRefContext";
import { Api } from "../../helpers/api";
import { rpsApi } from "../../helpers/api-config";
import { createDebugLogger } from "../../helpers/debug";
import Enum from "../../helpers/enums";
import Game from "../../models/Game";
import Match from "../../models/Match";
import Round from "../../models/Round";
import {
  commaSeparateWithNMore,
  gameNoun,
  getTournamentStatusStuff,
  toHtmlDateTimeFormat,
} from "../../utilities";

import "./GroupPage.scss";

const {
  log: hubLog,
  logGroup: hubLogGroup,
  logGroupEnd: hubLogGroupEnd,
} = createDebugLogger(Enum.DebugChannel.RPSHUB);
const { log: groupLog } = createDebugLogger(Enum.DebugChannel.GROUP);

class GroupPage extends React.Component {
  // eslint-disable-next-line
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.onlineUserUpdater = null;
    this.nextRpsEventTimer = null;

    this.shareButtonRef = React.createRef();
    this.touchStartX = null;
    this.touchStartY = null;

    this.stakeInputRef = React.createRef();

    this.state = {
      prevAuthState: null,

      slug: this.props.match.params.slug,

      group: null,
      groupLoading: true,
      groupNotFound: false,
      groupView: Enum.GroupView.DEFAULT,
      groupStart: "",
      groupLatestEnd: "",
      groupTab: Enum.GroupTab.QUICK,
      quickPlayers: 0,
      quickRsvps: 0,
      startTimeValidity: 0,

      tournamentPreparing: false,
      tournamentStarting: false,

      currentTournament: null,
      currentRound: null,
      currentMatch: null,
      currentGame: null,
      currentMove: null,
      opponentHasMoved: false,

      viewingRepeatingId: null,
      viewingRepeatingGame: null,

      rpsEventQueue: [{ event: { typeSlug: "page-load" } }],
      rpsEventCurrent: [],

      confirmLeaveGroup: false,
      confirmStartOpen: false,
      confirmCancelOpen: false,

      modalUserManageOpen: false,
      modalUserManageId: null,
      modalUserManageObj: null,
      modalUserManageKey: new Date(),

      modalNewGroupOpen: false,
      modalNewGroupStake: null,
      modalNewGroupKey: new Date(),

      modalDecisionRescheduleOpen: false,
      modalDecisionRescheduleStake: null,
      modalDecisionRescheduleKey: new Date(),

      modalHowToPlayOpen: false,
      modalHowToPlayKey: new Date(),

      modalGroupNameOpen: false,
      modalGroupNameKey: new Date(),

      modalInviteOpen: false,
      modalInviteKey: new Date(),

      modalDecisionParticipantsOpen: false,
      modalDecisionParticipantsKey: new Date(),

      modalInstallAppEnableNotificationsOpen: false,
      modalInstallAppEnableNotificationsKey: new Date(),

      modalDecisionOptionsOpen: false,
      modalDecisionOptionsKey: new Date(),

      modalJoinGameOpen: false,
      modalJoinGameKey: new Date(),

      modalAlertOpen: false,
      modalAlertKey: new Date(),
      modalAlertHeader: "Alert",
      modalAlertContent: "",

      modalRulesOpen: false,
      modalRulesKey: new Date(),

      notificationsClicked: false,

      userSlideOutOpen: false,
      userSlideOutTranslateX: 0,
      userSlideOutDragging: false,
      slideOutTouchDirection: null,
      tournamentSlideOutOpen: false,
      tournamentSlideOutTranslateX: 0,
      tournamentSlideOutDragging: false,
      lastSlideOutOpened: "user",

      joyrideTourRun: false,
      joyrideTourKey: "",
    };

    const funcsToBind = [
      "deregisterHubListeners",
      "getGroup",
      "handleConfigChange",
      "handleDraftTournamentAccountChange",
      "handleJoinTournament",
      "handleLeaveGroup",
      "handleLeaveGroupCancel",
      "handleLeaveGroupConfirm",
      "handleLeaveTournament",
      "handleGroupHistoryClick",
      "handleModalTournamentManageClose",
      "handleModalTournamentManageOpen",
      "handleModalAlertClose",
      "handleModalAlertOpen",
      "handleModalNewGroupClose",
      "handleModalNewGroupOpen",
      "handleModalHowToPlayClose",
      "handleModalHowToPlayOpen",
      "handleModalGroupNameClose",
      "handleModalGroupNameOpen",
      "handleModalInviteClose",
      "handleModalInviteOpen",
      "handleModalDecisionParticipantsClose",
      "handleModalDecisionParticipantsOpen",
      "handleModalDecisionOptionsClose",
      "handleModalDecisionOptionsOpen",
      "handleModalJoinGameClose",
      "handleModalJoinGameOpen",
      "handleModalRulesClose",
      "handleModalRulesOpen",
      "handleModalUserManageClose",
      "handleModalUserManageOpen",
      "handleMoveClick",
      "handleReceiveDraftTournamentUpdate",
      "handleReceiveGroupAccountsUpdate",
      "handleReceiveInitialTournament",
      "handleReceivePreparingTournament",
      "handleReceiveCloseTournament",
      "handleReceiveRpsEvents",
      "handleReceiveStartTournament",
      "handleReceiveStartRound",
      "handleRemoveAccount",
      "handleGroupAccountJoin",
      "handleGroupAccountLeave",
      "handleGroupAccountUpdate",
      "handleTournamentJoined",
      "handleTournamentLeft",
      "handlePastDecisionsClick",
      "handleReturnToGroupClick",
      "handleRpsEventContinue",
      "handlePrepareClick",
      "handleStartClick",
      "handleStartConfirm",
      "handleStartCancel",
      "handleCancelClick",
      "handleCancelConfirm",
      "handleCancelCancel",
      "joinGroup",
      "leaveGroup",
      "deleteGroup",
      "patchStateFromRpsEventPre",
      "patchStateFromRpsEventPost",
      "processGroup",
      "processGroupAccounts",
      "processRpsEventQueue",
      "processRpsEvents",
      "processTournament",
      "registerHubListeners",
      "handleLoadMoreDecisionHistory",
      "handleTabChange",
      "handleSwitchToRepeating",
      "resetDraftTournament",
      "newRepeatingDecisionValid",
      "handleEnableNotifications",
      "handleDecisionReplay",
      "handleViewRepeatingTournament",
      "handleViewRepeatingTournamentCancel",
      "onUserSlideoutOpen",
      "onUserSlideoutClose",
      "onTournamentSlideoutOpen",
      "onTournamentSlideoutClose",
      "handleTouchStart",
      "handleTouchEnd",
      "handleTouchMove",
      "handleNewRepeatingGame",
      "handlePrepareClickRepeating",
      "handleLeaveTournamentViewing",
      "handleJoinTournamentViewing",
      "handleJoinInstantRpsTournament",
      "handleMakeMoveClick",
      "handleCloseBoard",
      "sortGroupAccounts",
      "bubbleGroupNameAndMembers",
      "setStakeInputRef",
      "startJoyrideTour",
      "callbackJoyrideTour",
      "endJoyrideTour",
      "handleRandomGameDrawTransitionEnd",
      "handleResumeSolo",
      "handleForfeit",
    ];

    funcsToBind.map((fn) => (this[fn] = this[fn].bind(this)));
  }

  componentDidMount() {
    var qs = queryString.parse(this.props.location.search);
    var component = this;

    this.setState({ justCreated: qs.created === null });

    if (this.props.pathIsHistory)
      this.setState({ groupView: Enum.GroupView.HISTORY });

    if (this.props.pathIsPlay)
      this.setState({ groupView: Enum.GroupView.TOURNAMENTBOARD });

    if (this.props.pathTournamentId)
      this.setState({ historyTournamentId: this.props.pathTournamentId });

    this.getGroup((group) => {
      //if (qs.created === null) {
      //if (group?.myDraftTournament?.state === Enum.TournamentState.DRAFT)
      //Api.prepareTournament(group.myDraftTournament.id);

      //component.handleModalInviteOpen();
      //}

      // if (
      //   group.promo &&
      //   group.promoState < Enum.PromoState.PROMO_STARTED &&
      //   !group.amAssistant
      // )
      //   this.props.history.push(`/promo`);

      // When the hub connects for the first time, run this
      component.props.registerHubStateListener("connected", () => {
        hubLog("[GroupPage]: running hub connected event");

        component.registerHubListeners();

        hubLog("[GroupPage]: connecting - subscribing to group", group.id);
        component.props.hub
          .invoke("SubscribeToGroup", group.id)
          .then(() => component.props.onGroupSubscribed(group))
          .catch((err) => hubLog("[GroupPage]: SubscribeToGroup error!", err));
      });

      // When the hub disconnects unexpectedly, run this
      //this.props.registerHubStateListener("disconnected", () => {
      //
      //});

      component.props.registerHubStateListener("reconnecting", () => {
        hubLog("[GroupPage]: running hub reconnecting event");
        component.setState({ groupLoading: false });
      });

      // Whenever the hub reconnects after an interruption, get the full group details
      // again in case anything has updated in the meantime, then resubscribe to updates
      component.props.registerHubStateListener("reconnected", () => {
        hubLog("[GroupPage]: running hub reconnected event");

        component.setState({ groupLoading: true });

        component.getGroup((group) => {
          hubLog(
            "[GroupPage]: reconnecting - resubscribing to group",
            group.id,
          );
          component.props.hub
            .invoke("SubscribeToGroup", group.id)
            .then(() => this.props.onGroupSubscribed(group))
            .catch((err) =>
              hubLog("[GroupPage]: SubscribeToGroup error!", err),
            );

          if (component.state.currentMatch) {
            hubLog(
              "[GroupPage]: reconnecting - resubscribing to match",
              this.state.currentMatch.id,
            );
            component.props.hub
              .invoke("SubscribeToMatch", this.state.currentMatch.id)
              .catch((err) =>
                hubLog("[GroupPage]: SubscribeToMatch error!", err),
              );
          }
        });
      });
    });
  }

  componentWillUnmount() {
    this.unmounted = true;

    this.props.clearInterval(this.onlineUserUpdater);
    this.props.clearTimeout(this.nextRpsEventTimer);

    if (!this.props.hub || !this.state.group) return;

    this.props.clearHubStateListeners();
    this.deregisterHubListeners();

    hubLog(
      "[GroupPage]: unmounting - unsubscribing from group",
      this.state.group.id,
    );
    this.props.hub
      .invoke("UnsubscribeFromGroup", this.state.group.id)
      .then(() => this.props.onGroupSubscribed(null))
      .catch((err) => hubLog("[GroupPage]: UnsubscribeFromGroup error!", err));
    if (this.state.currentMatch) {
      hubLog(
        "[GroupPage]: unmounting - unsubscribing from match",
        this.state.currentMatch.id,
      );
      this.props.hub
        .invoke("UnsubscribeFromMatch", this.state.currentMatch.id)
        .then(() => this.props.onGroupSubscribed(null))
        .catch((err) =>
          hubLog("[GroupPage]: UnsubscribeFromMatch error!", err),
        );
    }
  }

  componentDidUpdate(_prevProps, prevState) {
    if (
      prevState.prevAuthState == null ||
      !_.isEqual(
        this.context.authState.isLoading,
        prevState.prevAuthState.isLoading,
      ) ||
      !_.isEqual(
        this.context.authState.isAuthenticated,
        prevState.prevAuthState.isAuthenticated,
      ) ||
      !_.isEqual(
        this.context.authState.account,
        prevState.prevAuthState.account,
      )
    ) {
      this.setState(() => {
        return {
          prevAuthState: JSON.parse(JSON.stringify(this.context.authState)),
        };
      });
    }

    if (
      prevState.currentMatch !== this.state.currentMatch &&
      prevState.currentMatch?.id !== this.state.currentMatch?.id
    ) {
      if (prevState.currentMatch) {
        hubLog(
          "[GroupPage]: unsubscribing from match",
          prevState.currentMatch.id,
        );
        this.props.hub
          .invoke("UnsubscribeFromMatch", prevState.currentMatch.id)
          .catch((err) =>
            hubLog("[GroupPage]: UnsubscribeFromMatch error!", err),
          );
      }

      if (this.state.currentMatch) {
        hubLog("[GroupPage]: subscribing to match", this.state.currentMatch.id);
        this.props.hub
          .invoke("SubscribeToMatch", this.state.currentMatch.id)
          .catch((err) => hubLog("[GroupPage]: SubscribeToMatch error!", err));
      }
    }

    if (prevState.currentTournament && !this.state.currentTournament) {
      this.onTournamentSlideoutClose();
    }

    if (_prevProps.pathIsPlay && !this.props.pathIsPlay)
      this.setState({ groupView: Enum.GroupView.DEFAULT });
    else if (!_prevProps.pathIsPlay && this.props.pathIsPlay) {
      this.onTournamentSlideoutClose();
      this.onUserSlideoutClose();
      this.setState({ groupView: Enum.GroupView.TOURNAMENTBOARD });
    }

    if (_prevProps.pathIsHistory && !this.props.pathIsHistory)
      this.setState({ groupView: Enum.GroupView.DEFAULT });
    else if (!_prevProps.pathIsHistory && this.props.pathIsHistory) {
      this.onTournamentSlideoutClose();
      this.onUserSlideoutClose();
      this.setState({ groupView: Enum.GroupView.HISTORY });
    }

    if (
      (_prevProps.pathIsCancelled && !this.props.pathIsCancelled) ||
      (_prevProps.pathIsResults && !this.props.pathIsResults)
    )
      this.setState((prevState) => {
        return {
          currentTournament: null,
          group: {
            ...prevState.group,
            myDraftTournament: this.resetDraftTournament(),
          },
        };
      });

    if (prevState.viewingRepeatingId !== this.state.viewingRepeatingId) {
      this.setState({
        viewingRepeatingGame: this.state.group?.repeatingTournaments?.find(
          (rt) => rt.id === this.state.viewingRepeatingId,
        ),
      });
    }

    if (prevState.groupView !== this.state.groupView) {
      groupLog(
        "groupView changed from ",
        prevState.groupView,
        " to ",
        this.state.groupView,
      );

      this.endJoyrideTour();
      this.handleRandomGameDrawTransitionEnd(true);
    }

    if (prevState.groupTab !== this.state.groupTab) {
      groupLog(
        "groupTab changed from ",
        prevState.groupTab,
        " to ",
        this.state.groupTab,
      );

      this.endJoyrideTour();
      this.handleRandomGameDrawTransitionEnd(true);
    }

    const { currentTournament, currentRound, group } = this.state;

    // Check if any of the dependencies has changed
    if (
      currentTournament !== prevState.currentTournament ||
      currentRound !== prevState.currentRound ||
      group?.playerCount !== prevState.group?.playerCount
    ) {
      const tss = getTournamentStatusStuff(
        group,
        currentTournament,
        currentRound,
        this.context.authState?.account,
      );

      if (
        tss &&
        (prevState.tournamentStatusIcon !== tss.tournamentStatusIcon ||
          prevState.tournamentStatusText !== tss.tournamentStatusText)
      )
        this.setState({
          tournamentStatusIcon: tss.tournamentStatusIcon,
          tournamentStatusText: tss.tournamentStatusText,
        });
    }
  }

  setStakeInputRef = (element) => {
    this.stakeInputRef.current = element;
  };

  startJoyrideTour = (tourKey) => {
    this.setState({
      joyrideTourRun: true,
      joyrideTourKey: tourKey,
      joyrideKey: new Date(),
    });
  };

  callbackJoyrideTour = (data) => {
    if (data.action === "close" || data.type === "tour:end") {
      this.setState({
        joyrideTourRun: false,
      });
    }
  };

  endJoyrideTour = () => {
    this.setState({
      joyrideTourRun: false,
    });
  };

  registerHubListeners() {
    // Should only initialise if group has loaded, and we are a Spectator or higher
    if (!this.state.group || this.unmounted) return;

    hubLog("[GroupPage]: registering hub event listeners");

    this.props.hub.on("receiveRpsEvents", this.handleReceiveRpsEvents);
    this.props.hub.on(
      "receiveGroupAccountsUpdate",
      this.handleReceiveGroupAccountsUpdate,
    );
    this.props.hub.on(
      "receiveDraftTournamentUpdate",
      this.handleReceiveDraftTournamentUpdate,
    );
    this.props.hub.on(
      "receiveInitialTournament",
      this.handleReceiveInitialTournament,
    );
  }

  deregisterHubListeners() {
    hubLog("[GroupPage]: deregistering hub event listeners");

    this.props.hub.off("receiveRpsEvents", this.handleReceiveRpsEvents);
    this.props.hub.off(
      "receiveGroupAccountsUpdate",
      this.handleReceiveGroupAccountsUpdate,
    );
    this.props.hub.off(
      "receiveDraftTournamentUpdate",
      this.handleReceiveDraftTournamentUpdate,
    );
    this.props.hub.off(
      "receiveInitialTournament",
      this.handleReceiveInitialTournament,
    );
  }

  handleLeaveGroup() {
    this.setState({ confirmLeaveGroup: true });
  }

  handleLeaveGroupConfirm() {
    rpsApi
      .delete(`/groupaccount/${this.state.group.id}`)
      .then(() => {
        this.setState({ confirmLeaveGroup: false });
        this.props.history.push(
          `/g/new?left=${encodeURIComponent(this.state.group.name)}`,
        );
      })
      .catch((response) => {
        groupLog("Error leaving group.", response);
        this.setState({ confirmLeaveGroup: false });
      });
  }

  handleLeaveGroupCancel() {
    this.setState({ confirmLeaveGroup: false });
  }

  handleMoveClick(moveType, success, error) {
    const { currentGame, currentMatch, currentTournament } = this.state;
    const { authState } = this.context;

    rpsApi
      .post("/move", {
        tournamentId: currentTournament.id,
        gameId: currentGame.id,
        accountId: authState?.account?.id,
        moveType: moveType,
      })
      .then((response) => {
        if (!response || this.unmounted) return;

        this.setState((prevState) => {
          const newGame = Game.patchGameIMovedEvent(currentGame, {
            id: response.data,
            accountId: authState?.account?.id,
            moveType: moveType,
          });

          const newMatch = Match.patchMatchIMovedEvent(currentMatch, newGame);

          const newState = {
            currentTournament: {
              ...prevState.currentTournament,
              myMatch: {
                ...prevState.currentTournament.myMatch,
                ...newMatch,
              },
            },
            currentGame: newGame,
            currentMatch: newMatch,
          };

          newState.currentMove = newGame.moves.find(
            (m) => m.accountId === authState?.account?.id,
          );

          return newState;
        });

        if (success) success(response);
      })
      .catch(function (response) {
        if (error) error(response);
      });
  }

  handleRemoveAccount(id) {
    var component = this;

    rpsApi.delete(`/groupaccount/${component.state.slug}?accountid=${id}`);
  }

  handleReceiveRpsEvents(rpsEvents) {
    this.processRpsEvents(rpsEvents);
  }

  handleReceiveGroupAccountsUpdate() {
    //this.processGroupAccounts(groupAccounts);
  }

  handleReceiveDraftTournamentUpdate(tournament) {
    tournament = this.processTournament(tournament);

    hubLogGroup("Received draft tournament update:");
    hubLog(tournament);
    hubLogGroupEnd();

    this.setState((prevState) => {
      return { group: { ...prevState.group, myDraftTournament: tournament } };
    });
  }

  handleReceiveInitialTournament(tournament) {
    const { authState } = this.context;

    hubLogGroup("Received initial tournament:");
    hubLog(tournament);
    hubLogGroupEnd();

    tournament = this.processTournament(tournament);

    this.setState({ groupLoading: false, tournamentLoading: false });
    hubLog(tournament.state);
    if (!tournament) return;
    if (tournament.state === 0) {
      hubLog("state 0");
      tournament.startTime = toHtmlDateTimeFormat(moment().add(1, "hour"));
      if (!tournament.prepareLengthMinutes)
        tournament.prepareLengthMinutes = 60;

      this.setState((prevState) => {
        return { group: { ...prevState.group, myDraftTournament: tournament } };
      });
    } else {
      hubLog("state not 0");
      var currentGame =
        tournament.myMatch ?
          Game.fromTournamentInit(tournament.myMatch.games[0])
        : null;
      hubLog("currentGame", currentGame);
      var currentMove =
        currentGame ?
          currentGame.moves.find((m) => m.accountId === authState?.account?.id)
        : null;
      hubLog("currentMove", currentMove);
      this.setState(
        (prevState) => {
          const sortedGas = [...prevState.group.groupAccounts];
          this.sortGroupAccounts(
            sortedGas,
            tournament.activeRoundMatchAccountList,
            tournament.state === Enum.TournamentState.ACTIVE,
          );

          tournament.myMatch = Match.fromTournamentInit(
            tournament.myMatch,
            this.context.authState?.account,
            tournament.stakeIsPositive,
          );

          return {
            group: {
              ...prevState.group,
              groupAccounts: sortedGas,
            },
            currentTournament: tournament,
            currentRound: Round.fromTournamentInit(
              tournament.activeRound,
              tournament.activeRoundMatchAccountList,
            ),
            currentMatch: tournament.myMatch,
            currentGame,
            currentMove,
          };
        },
        () => {
          this.processRpsEventQueue();
        },
      );
    }
  }

  handleReceiveCloseTournament(closedTournament) {
    hubLogGroup("Received close tournament event:");
    hubLog(closedTournament);
    hubLogGroupEnd();

    if (!closedTournament) return;

    if (
      closedTournament.startMode === Enum.TournamentStartMode.REPEATING ||
      closedTournament.state === Enum.TournamentState.REPEATING
    ) {
      if (closedTournament.id === this.state.viewingRepeatingId) {
        this.setState({
          viewingRepeatingId: null,
          viewingRepeatingGame: null,
          groupView: Enum.GroupView.DEFAULT,
        });
      }
    } else if (
      closedTournament.gameMode === Enum.TournamentGameMode.TOURNAMENTRPS
    ) {
      this.setState((prevState) => {
        const sortedGas = [...prevState.group.groupAccounts];
        this.sortGroupAccounts(sortedGas, [], false);

        return {
          group: {
            ...prevState.group,
            groupAccounts: sortedGas,
          },
        };
      });
    }
  }

  handleReceivePreparingTournament(preparingTournament) {
    const { authState } = this.context;

    hubLogGroup("Received preparing tournament event:");
    hubLog(preparingTournament);
    hubLogGroupEnd();

    this.setState({ tournamentLoading: false });

    if (!preparingTournament) return;
    else {
      if (preparingTournament.state === Enum.TournamentState.REPEATING) {
        this.setState((prevState) => {
          let pt = { ...preparingTournament };
          pt.participants = 1;
          if (pt.creatorId === authState?.account?.id)
            pt.amParticipating = true;

          return {
            group: {
              ...prevState.group,
              repeatingTournaments:
                prevState.group.repeatingTournaments ?
                  [pt, ...prevState.group.repeatingTournaments]
                : [pt],
            },
          };
        });
      } else {
        if (
          (this.state.group.amBoss || this.state.group.amAssistant) &&
          preparingTournament.creatorId !== authState?.account?.id
        ) {
          this.handleModalAlertOpen(
            "Game preparing",
            "Another group member is preparing their game. You can continue setting up your game once the current one ends.",
          );
        }

        this.setState({
          currentTournament: {
            ...preparingTournament,
            amParticipating:
              preparingTournament.creatorId === authState?.account?.id ?
                true
              : null,
            tournamentAccounts: [
              {
                accountId: preparingTournament.creatorId,
                accountUserName: preparingTournament.creatorUserName,
                participating: true,
              },
            ],
            tournamentAccountsLookup: [preparingTournament.creatorId],
            participants: 1,
          },
        });
      }

      if (
        preparingTournament.creatorId === authState?.account?.id &&
        this.state.group.amBoss &&
        (this.state.group.finishedGameCount === 0 ||
          this.state.group.groupAccounts.length === 1)
      ) {
        this.props.setTimeout(() => {
          this.handleModalInviteOpen();
        }, 500);
      }
    }
  }

  handleReceiveRescheduledTournament(preparingTournament) {
    hubLogGroup("Received rescheduled tournament event:");
    hubLog(preparingTournament);
    hubLogGroupEnd();

    if (!preparingTournament) return;
    else {
      if (preparingTournament.startMode === Enum.TournamentStartMode.QUICK) {
        if (
          !this.state.currentTournament ||
          this.state.currentTournament.id !== preparingTournament.id
        )
          return;

        this.setState((prevState) => {
          return {
            currentTournament: {
              ...prevState.currentTournament,
              startTime: preparingTournament.startTime,
              rescheduleCount:
                prevState.currentTournament.rescheduleCount ?
                  prevState.currentTournament.rescheduleCount + 1
                : 1,
            },
          };
        });
      } else {
        this.setState((prevState) => {
          if (prevState.group?.repeatingTournaments?.length) {
            let newRepeating = JSON.parse(
              JSON.stringify(prevState.group.repeatingTournaments, "", 2),
            );
            let repeatingIdx = newRepeating.findIndex(
              (x) => x.id === preparingTournament.id,
            );
            if (repeatingIdx === -1) return;

            newRepeating[repeatingIdx].startTime =
              preparingTournament.startTime;
            newRepeating[repeatingIdx].repeatPeriod =
              preparingTournament.repeatPeriod;
            newRepeating[repeatingIdx].repeatEvery =
              preparingTournament.repeatEvery;
            newRepeating[repeatingIdx].repeatDayOfMonth =
              preparingTournament.repeatDayOfMonth;
            newRepeating[repeatingIdx].repeatDaysOfWeek =
              preparingTournament.repeatDaysOfWeek;
            newRepeating[repeatingIdx].winnersToDraw =
              preparingTournament.winnersToDraw;

            let newViewRepeatingGame = prevState.viewingRepeatingGame;
            if (
              this.state.viewingRepeatingId === preparingTournament.id &&
              !!this.state.viewingRepeatingGame
            )
              newViewRepeatingGame = newRepeating[repeatingIdx];

            let newState = {
              group: {
                ...prevState.group,
                repeatingTournaments: newRepeating,
              },
              viewingRepeatingGame: newViewRepeatingGame,
            };

            return newState;
          }
        });
      }
    }
  }

  handleReceiveRepeatingTournamentRolledForward(rolledForwardTournament) {
    hubLogGroup("Received repeating tournament rolled forward event:");
    hubLog(rolledForwardTournament);
    hubLogGroupEnd();

    if (!rolledForwardTournament) return;

    this.setState((prevState) => {
      if (prevState.group?.repeatingTournaments?.length) {
        let newRepeating = JSON.parse(
          JSON.stringify(prevState.group.repeatingTournaments, "", 2),
        );
        let repeatingIdx = newRepeating.findIndex(
          (x) => x.id === rolledForwardTournament.id,
        );
        if (repeatingIdx === -1) return;

        newRepeating[repeatingIdx].startTime =
          rolledForwardTournament.startTime;

        let newState = {
          group: {
            ...prevState.group,
            repeatingTournaments: newRepeating,
          },
          viewingRepeatingGame: {
            ...prevState.viewingRepeatingGame,
            startTime: rolledForwardTournament.startTime,
          },
        };

        return newState;
      }
    });
  }

  handleReceiveStartTournament(startTournament) {
    hubLogGroup("Received start tournament event:");
    hubLog(startTournament);
    hubLogGroupEnd();

    this.setState({ tournamentLoading: false });

    if (!startTournament) return;
    else if (
      startTournament.gameMode === Enum.TournamentGameMode.TOURNAMENTRPS
    ) {
      if (
        !this.state.currentTournament ||
        this.state.currentTournament.id !== startTournament.id
      ) {
        hubLog(
          "Current tournament does not exist, or its ID is not the same as the one in the hub update, ignore",
        );
        return;
      }

      startTournament = this.processTournament(startTournament);

      this.setState(
        (prevState) => {
          const newState = {
            currentTournament: { ...startTournament },
            currentRound: Round.fromTournamentInit(
              startTournament.rounds[startTournament.rounds.length - 1],
              startTournament.matchAccountList,
            ),
            groupView: prevState.groupView,
          };

          // all tournament accounts that are in this tournament should update their associated group account with isCurrentlyPlaying to true
          if (startTournament.tournamentAccounts) {
            newState.group = {
              ...prevState.group,
              groupAccounts: prevState.group.groupAccounts.map((ga) => ({
                ...ga,
                isCurrentlyPlaying:
                  startTournament.tournamentAccountsLookup.includes(
                    ga.accountId,
                  ),
                aiOpponent: false,
                listMatchPosition: null,
                matchComplete: false,
                hasMoved: false,
              })),
            };
          }

          this.sortGroupAccounts(
            newState.group.groupAccounts,
            startTournament.matchAccountList,
            true,
          );

          if (startTournament.myMatch) {
            startTournament.myMatch.roundNumber = 1;
            startTournament.myMatch.roundTitle = "Round 1";

            newState.currentMatch = Match.fromTournamentInit(
              startTournament.myMatch,
            );
            newState.currentRound.matches = [newState.currentMatch];
            newState.currentGame = Game.fromTournamentInit(
              startTournament.myMatch.games[0],
            );
          }

          return newState;
        },
        () => {
          if (
            startTournament.myMatch &&
            startTournament.startingPlayers === 2
          ) {
            this.handleMakeMoveClick();
          }
        },
      );
    } else if (
      startTournament.startMode === Enum.TournamentStartMode.REPEATING
    ) {
      // check repeating
      groupLog("updating repeating decision");
      this.setState((prevState) => {
        groupLog(
          "prevState.group?.repeatingTournaments?.length",
          prevState.group?.repeatingTournaments?.length,
        );
        if (prevState.group?.repeatingTournaments?.length) {
          let newRepeating = [...prevState.group.repeatingTournaments];
          let repeatingIdx = newRepeating.findIndex(
            (x) =>
              x.id ===
              (startTournament.repeatParentId ?
                startTournament.repeatParentId
              : startTournament.id),
          );
          groupLog("repeatingIdx", repeatingIdx);
          if (repeatingIdx === -1) return;

          newRepeating[repeatingIdx].startTime = startTournament.startTime;

          // Repeating tournament package puts cloned time in "endTime" and the next repeat time in "startTime"
          startTournament = {
            ...startTournament,
            startTime: startTournament.endTime,
            endTime: null,
            amParticipating: newRepeating[repeatingIdx].amParticipating,
          };

          let newStarted =
            prevState.group.startedTournaments?.data ?
              [startTournament, ...prevState.group.startedTournaments.data]
            : [startTournament];

          let newState = {
            group: {
              ...prevState.group,
              repeatingTournaments: newRepeating,
            },
          };

          if (startTournament.repeatParentId) {
            newState.group.startedTournaments = {
              ...prevState.group.startedTournaments,
              allCount: prevState.group.startedTournaments.allCount + 1,
              repeatingCount:
                prevState.group.startedTournaments.repeatingCount + 1,
              data: newStarted,
            };
          }

          return newState;
        }
      });
    } else {
      if (
        !this.state.currentTournament ||
        this.state.currentTournament.id !== startTournament.id
      )
        return;

      this.setState({
        randomGameDrawTransitionShow: true,
        randomGameDrawTransitionTournament: startTournament,
      });
    }
  }

  handleRandomGameDrawTransitionEnd(runningEarlyDueToViewChange) {
    if (!this.state.randomGameDrawTransitionShow) return;

    if (
      !runningEarlyDueToViewChange &&
      (this.state.groupView !== Enum.GroupView.DEFAULT ||
        this.state.groupTab !== Enum.GroupTab.QUICK)
    ) {
      this.setState({
        randomGameDrawTransitionShow: false,
        randomGameDrawTransitionTournament: null,
      });
      return;
    }

    //if (!runningEarlyDueToViewChange) this.handleGroupHistoryClick(true);

    let startTournament = this.state.randomGameDrawTransitionTournament;

    if (!startTournament) {
      this.setState({
        currentTournament: null,
        randomGameDrawTransitionShow: false,
        randomGameDrawTransitionTournament: null,
      });

      return;
    }

    this.setState(
      (prevState) => {
        startTournament = {
          ...startTournament,
          amParticipating: prevState.currentTournament.amParticipating,
        };

        let newStarted =
          prevState.group.startedTournaments?.data ?
            [startTournament, ...prevState.group.startedTournaments.data]
          : [startTournament];

        let newState = {
          currentTournament: null,
          group: {
            ...prevState.group,
            startedTournaments: {
              ...(prevState.group.startedTournaments ?
                prevState.group.startedTournaments
              : {}),
              allCount: prevState.group.startedTournaments?.allCount + 1,
              quickCount: prevState.group.startedTournaments?.quickCount + 1,
              data: newStarted,
            },
            finishedGameCount: prevState.group.finishedGameCount + 1,
          },
          randomGameDrawTransitionShow: false,
          randomGameDrawTransitionTournament: null,
        };

        return newState;
      },
      () => {
        if (!runningEarlyDueToViewChange) {
          this.handleGroupHistoryClick(true);
          this.props.history.push(
            `/g/${this.state.slug}/history/${startTournament.id}/?direct=true`,
          );
        }
      },
    );
  }

  handleReceiveStartRound(startRound) {
    var fullMatch = null;

    if (startRound.myMatch) {
      // Still in
      var startMatch = startRound.myMatch;

      var fullGameAccounts = [],
        fullMatchAccounts = [],
        withAi = false;

      for (var i = 0; i < startMatch.matchAccounts.length; i++) {
        fullMatchAccounts.push({
          id: i,
          matchId: startMatch.id,
          score: 0,
          result: null,
          accountId: startMatch.matchAccounts[i].accountId,
          accountUserName: startMatch.matchAccounts[i].accountUserName,
        });

        fullGameAccounts.push({
          gameId: startMatch.gameId,
          result: null,
          accountId: startMatch.matchAccounts[i].accountId,
          accountUserName: startMatch.matchAccounts[i].accountUserName,
        });

        if (startMatch.matchAccounts[i].id === 1) withAi = true;
      }

      var fullGame = Game.fromRoundStart(
        startMatch,
        new Date(),
        fullGameAccounts,
        withAi,
      );

      fullMatch = Match.fromRoundStart(
        startMatch,
        fullMatchAccounts.length === 3,
        fullGame,
        fullMatchAccounts,
      );

      const historicalMatchAccounts = [];
      if (startRound.prevRoundMatches) {
        for (let i = 0; i < startRound.prevRoundMatches.length; i++) {
          startRound.prevRoundMatches[i].matchAccounts.forEach((ma) => {
            historicalMatchAccounts.push(ma);
          });
        }

        fullMatch.historicalMatchAccounts = historicalMatchAccounts;
      }
    }

    this.setState((prevState) => {
      // let myFurthestRound =
      //   !prevState.currentTournament.myFurthestRound && !startRound.myMatch
      //     ? prevState.currentTournament.rounds[
      //         prevState.currentTournament.rounds.length - 1
      //       ].id
      //     : prevState.currentTournament.myFurthestRound;

      if (!startRound.myMatch) {
        if (prevState.currentTournament.myMatch) {
          // Just knocked out
          fullMatch = { ...prevState.currentTournament.myMatch };
        } else {
          fullMatch = prevState.currentTournament.myHistoricalMatch;
        }
      } else if (fullMatch) {
        // Still in
        const { roundId, roundNumber, roundTitle } =
          prevState.currentTournament.myMatch;
        fullMatch.prevRoundId = roundId;
        fullMatch.prevRoundNumber = roundNumber;
        fullMatch.prevRoundTitle = roundTitle;

        fullMatch.nextRoundNumber = startRound.nextRoundNumber;
        fullMatch.nextRoundTitle = startRound.nextRoundTitle;

        fullMatch.roundNumber = startRound.roundNumber;
        fullMatch.roundTitle = startRound.roundTitle;
      }

      var fullRound = Round.fromRoundStart(startRound);

      // groupLog("!!!NEW ROUND!!! Number: ", fullRound.roundNumber);
      // groupLog("!!!NEW ROUND!!! Has My Match?", !!startRound.myMatch);
      // groupLog(
      //   "!!!NEW ROUND!!! Current Tournament Has My Match? (from old round)",
      //   !!prevState.currentTournament.myMatch
      // );

      let newRounds = [...prevState.currentTournament.rounds];
      let newRoundIdx = newRounds.findIndex((x) => x.id === fullRound.id);
      newRounds[newRoundIdx] = fullRound;

      // Sort group accounts in the following order: isBoss (true first, false next), next should be if the user is me (id === authState.account.id) then other users, next should be member.isCurrentlyPlaying (true first, false next)
      let newGroupAccounts = [...prevState.group.groupAccounts];

      this.sortGroupAccounts(
        newGroupAccounts,
        fullRound.matchAccountList,
        true,
      );

      newGroupAccounts.forEach((ga) => {
        ga.isCurrentlyPlaying = fullRound.matchAccountList.some(
          (x) => x.accountId === ga.accountId,
        );
        ga.matchComplete = false;
        ga.hasMoved = false;
      });

      return {
        group: {
          ...prevState.group,
          groupAccounts: newGroupAccounts,
        },
        currentTournament: {
          ...prevState.currentTournament,
          rounds: newRounds,
          //myFurthestRound,
          myMatch: startRound.myMatch ? fullMatch : null,
          myHistoricalMatch: startRound.myMatch ? null : fullMatch,
        },
        currentRound: fullRound,
        currentMatch: fullMatch,
        currentGame: fullMatch ? fullMatch.games[0] : null,
        currentMove: null,
      };
    });
  }

  sortGroupAccounts(groupAccounts, matchAccounts, tournamentPlaying) {
    function getMatchIdForAccount(accountId, matchAccounts) {
      const match = matchAccounts.find((m) => m.accountId === accountId);
      return match ? match.matchId : Number.MAX_SAFE_INTEGER;
    }

    function getOpponentIdForAccount(accountId, matchAccounts) {
      const match = matchAccounts?.find((m) => m.accountId === accountId);
      if (!match) return null;
      const otherMatch = matchAccounts.find(
        (m) => m.matchId === match.matchId && m.accountId !== accountId,
      );
      return otherMatch ? otherMatch.accountId : null;
    }

    function customSort(a, b, matchAccounts, accountId, tournamentPlaying) {
      console.log("CUSTOM SORT, TOURNAMENT PLAYING: ", tournamentPlaying);
      // Admin should always be first unless tournament in playing
      if (!tournamentPlaying) {
        if (a.isBoss) return -1;
        if (b.isBoss) return 1;
      }

      // 'isMe' should come right after admin or admin's opponent
      if (a.accountId === accountId) return -1;
      if (b.accountId === accountId) return 1;

      // If both a and b have matches, sort by match ID
      const matchIdA = getMatchIdForAccount(a.accountId, matchAccounts);
      const matchIdB = getMatchIdForAccount(b.accountId, matchAccounts);
      if (matchIdA !== matchIdB) return matchIdA - matchIdB;

      // If match IDs are the same or if either doesn't have a match, sort by username
      return a.accountUserName.localeCompare(b.accountUserName);
    }

    function setListMatchPosition(sortedAccounts, matchAccounts, meId) {
      for (let i = 0; i < sortedAccounts.length; i++) {
        const accountId = sortedAccounts[i].accountId;
        const matchId = getMatchIdForAccount(accountId, matchAccounts);

        // If there's no corresponding match for the account
        if (matchId === Number.MAX_SAFE_INTEGER) {
          sortedAccounts[i].listMatchPosition =
            Enum.GroupListMatchPosition.NO_MATCH;
          continue;
        }

        const opponentId = getOpponentIdForAccount(accountId, matchAccounts);

        if (sortedAccounts[i].accountId === meId) {
          // If there's an opponent in the match for the account and it's right after current account
          if (
            opponentId &&
            sortedAccounts[i + 1] &&
            sortedAccounts[i + 1].accountId === opponentId
          ) {
            sortedAccounts[i].listMatchPosition =
              Enum.GroupListMatchPosition.TOP;
            sortedAccounts[i + 1].listMatchPosition =
              Enum.GroupListMatchPosition.BOTTOM;
            i++; // Skip the next iteration since we already set the listMatchPosition for the opponent
          } else {
            sortedAccounts[i].listMatchPosition =
              Enum.GroupListMatchPosition.NO_MATCH; // Only isAdmin or isMe is in the match
          }
          continue;
        }

        // If there's no opponent in the match for the account, or if the account comes before its opponent
        if (
          !opponentId ||
          (sortedAccounts[i + 1] &&
            sortedAccounts[i + 1].accountId === opponentId)
        ) {
          sortedAccounts[i].listMatchPosition = Enum.GroupListMatchPosition.TOP;
        } else {
          sortedAccounts[i].listMatchPosition =
            Enum.GroupListMatchPosition.BOTTOM;
        }

        sortedAccounts[i].aiOpponent =
          opponentId === config.aiAccountMember.accountId;
      }

      return sortedAccounts;
    }

    const { authState } = this.context;
    const meId = authState?.account?.id;
    if (!matchAccounts) matchAccounts = [];

    const sortedAccounts = groupAccounts.sort((a, b) =>
      customSort(
        a,
        b,
        matchAccounts,
        meId,
        tournamentPlaying ||
          this.state.currentTournament?.state === Enum.TournamentState.ACTIVE,
      ),
    );

    const isMeIndex = sortedAccounts.findIndex((acc) => acc.accountId === meId);

    // Place the opponent of the 'isMe' user right after the 'isMe' user
    if (isMeIndex >= 0) {
      const opponentId = getOpponentIdForAccount(
        sortedAccounts[isMeIndex].accountId,
        matchAccounts,
      );

      if (!!opponentId && opponentId !== config.aiAccountMember.accountId) {
        const opponentIndex = sortedAccounts.findIndex(
          (acc) => acc.accountId === opponentId,
        );

        const [opponent] = sortedAccounts.splice(opponentIndex, 1);
        sortedAccounts.splice(isMeIndex + 1, 0, opponent);
      }
    }

    setListMatchPosition(sortedAccounts, matchAccounts);
  }

  handleDraftTournamentAccountChange(accountId, isIn) {
    this.setState((prevState) => {
      let mdt = prevState.group.myDraftTournament;
      if (!mdt.tournamentAccounts) mdt.tournamentAccounts = [];

      let idx = mdt.tournamentAccounts.findIndex(
        (ta) => ta.accountId === accountId,
      );

      if (!isIn && idx > -1) mdt.tournamentAccounts.splice(idx, 1);
      else if (isIn && idx === -1) mdt.tournamentAccounts.push({ accountId });
      else return;

      return {
        group: {
          ...prevState.group,
          myDraftTournament: this.processTournament(mdt),
        },
      };
    });
  }

  handleGroupAccountUpdate(subject, isAssistant) {
    const { accountId } = subject;
    const { authState } = this.context;

    groupLog("handleGroupAccountUpdate", accountId, isAssistant);
    this.setState((prevState) => {
      let gas = [...prevState.group.groupAccounts];
      if (!gas) return {};

      let idx = gas.findIndex((ga) => ga.accountId === accountId);

      if (idx > -1) {
        gas[idx].isAssistant = isAssistant;
        if (accountId === authState?.account?.id) {
          this.handleModalAlertOpen(
            isAssistant ? `Promoted to assistant` : `Demoted from assistant`,
            isAssistant ?
              `You have been promoted to group assistant. You can now start your own games in the group.`
            : `You have been demoted from group assistant.`,
          );
        }
      } else return;

      return {
        group: {
          ...prevState.group,
          groupAccounts: gas,
        },
      };
    });

    if (accountId !== authState?.account?.id) return;

    this.setState(
      (prevState) => {
        return {
          group: {
            ...prevState.group,
            amAssistant: isAssistant,
          },
          myDraftTournament: null,
          tournamentLoading: isAssistant,
        };
      },
      () => {
        if (isAssistant) this.refreshDraftTournament();
      },
    );
  }

  handleGroupAccountUserNameUpdate(subject) {
    const { accountId, userName } = subject;

    groupLog("handleGroupAccountUserNameUpdate", subject);

    // group account list
    this.setState((prevState) => {
      let gas = [...prevState.group.groupAccounts];
      if (!gas) return {};

      let idx = gas.findIndex((ga) => ga.accountId === accountId);
      if (idx > -1) {
        gas[idx].accountUserName = userName;
      } else return;

      return {
        group: {
          ...prevState.group,
          groupAccounts: gas,
        },
      };
    }, this.bubbleGroupNameAndMembers);

    // current tournament creator
    if (this.state.currentTournament?.creatorId === accountId) {
      this.setState((prevState) => {
        return {
          currentTournament: {
            ...prevState.currentTournament,
            creatorUserName: userName,
          },
        };
      });
    }

    // repeating tournament creator
    if (this.state.viewingRepeatingGame?.creatorId === accountId) {
      this.setState((prevState) => {
        return {
          viewingRepeatingGame: {
            ...prevState.viewingRepeatingGame,
            creatorUserName: userName,
          },
        };
      });
    }

    // repeating list
    if (this.state.group?.repeatingTournaments?.length) {
      this.setState((prevState) => {
        let newRepeating = [...prevState.group.repeatingTournaments];

        newRepeating.forEach((r) => {
          if (r.creatorId === accountId) r.creatorUserName = userName;
        });

        return {
          group: {
            ...prevState.group,
            repeatingTournaments: newRepeating,
          },
        };
      });
    }

    // group tournament history
    if (this.state.group?.startedTournaments?.data?.length) {
      this.setState((prevState) => {
        let newStarted = [...prevState.group.startedTournaments.data];

        newStarted.forEach((r) => {
          if (r.creatorId === accountId) r.creatorUserName = userName;
        });

        return {
          group: {
            ...prevState.group,
            startedTournaments: {
              ...prevState.group.startedTournaments,
              data: newStarted,
            },
          },
        };
      });
    }
  }

  handleGroupAccountJoin(newGroupAccount) {
    if (
      this.state.group.groupAccounts.find(
        (x) => x.accountId === newGroupAccount.accountId,
      )
    )
      return;

    this.setState((prevState) => {
      var newState = {
        group: {
          ...prevState.group,
          groupAccounts:
            prevState.group?.groupAccounts ?
              [...prevState.group.groupAccounts, newGroupAccount]
            : [newGroupAccount],
          playerCount:
            prevState.group?.playerCount ? prevState.group.playerCount + 1 : 1,
        },
        displayNewMemberTag: true,
      };

      return newState;
    }, this.bubbleGroupNameAndMembers);

    if (this.displayNewMemberTagTimer)
      this.props.clearTimeout(this.displayNewMemberTagTimer);

    this.displayNewMemberTagTimer = this.props.setTimeout(() => {
      this.setState({ displayNewMemberTag: false });
    }, 3000);
  }

  bubbleGroupNameAndMembers() {
    const { authState } = this.context;

    const groupMembersNamesStr = commaSeparateWithNMore(
      this.state.group.groupAccounts.map((ga) =>
        ga.accountId === authState?.account?.id ? "You" : ga.accountUserName,
      ),
      3,
      "Your group",
    );
    this.props.setCurrentGroup(this.state.group, groupMembersNamesStr);
  }

  handleGroupAccountLeave(accountId, repeatingUpdates) {
    if (repeatingUpdates.removeFrom?.length) {
      this.setState((prevState) => {
        let newRepeatings = [...prevState.group.repeatingTournaments];

        for (let i = 0; i < newRepeatings.length; i++) {
          if (repeatingUpdates.removeFrom.includes(newRepeatings[i].id)) {
            newRepeatings[i] = {
              ...newRepeatings[i],
              participants: Math.max(0, newRepeatings[i].participants - 1),
            };
          }
        }

        return {
          group: {
            ...prevState.group,
            repeatingTournaments: newRepeatings,
          },
        };
      });
    }

    if (repeatingUpdates.delete?.length) {
      this.setState((prevState) => {
        let newRepeatings = [...prevState.group.repeatingTournaments];
        let newViewRepeatingId = prevState.viewingRepeatingId;

        for (let i = 0; i < newRepeatings.length; i++) {
          if (repeatingUpdates.delete.includes(newRepeatings[i].id)) {
            if (
              this.state.viewingRepeatingId === newRepeatings[i].id &&
              !!newViewRepeatingId
            ) {
              newViewRepeatingId = null;
            }
            newRepeatings.splice(i, 1);
            break;
          }
        }
        let newState = {
          group: {
            ...prevState.group,
            repeatingTournaments: newRepeatings,
          },
        };

        if (newViewRepeatingId === null) {
          newState.viewingRepeatingId = null;
          newState.viewingRepeatingGame = null;
          newState.groupView = Enum.GroupView.DEFAULT;
          newState.groupTab = Enum.GroupTab.REPEATING;
        }

        return newState;
      });
    }

    this.handleTournamentLeft(
      { tournamentId: this.state.currentTournament?.id, accountId },
      true,
      () => {
        this.setState((prevState) => {
          let gidx = prevState.group.groupAccounts.findIndex(
            (x) => x.accountId === accountId,
          );

          // groupLog(
          //   "Found groupaccount with accountid ",
          //   accountId,
          //   " at position",
          //   gidx
          // );

          var gas = [...prevState.group.groupAccounts];
          //groupLog(gas);
          if (gidx >= 0) gas.splice(gidx, 1);
          //groupLog(gas);

          return {
            group: {
              ...prevState.group,
              groupAccounts: gas,
              playerCount: prevState.group.playerCount - 1,
            },
          };
        }, this.bubbleGroupNameAndMembers);
      },
    );
  }

  handleTournamentJoined(subject) {
    if (!subject || !subject.tournamentId) return;

    this.setState((prevState) => {
      // If no current tournament, likely a repeating one
      let isCurrent = false,
        repeatingIdx = -1;

      if (prevState.currentTournament)
        isCurrent = prevState.currentTournament.id === subject.tournamentId;

      if (!isCurrent && prevState.group.repeatingTournaments)
        repeatingIdx = _.findIndex(
          prevState.group.repeatingTournaments,
          (x) => x.id === subject.tournamentId,
        );

      if (!isCurrent && repeatingIdx === -1) return;

      if (isCurrent) {
        let tas = prevState.currentTournament.tournamentAccounts;
        let tal = prevState.currentTournament.tournamentAccountsLookup;

        if (!tas) {
          tas = [];
          tal = [];
        }

        let theTa =
          tas ? _.findIndex(tas, (t) => t.accountId === subject.accountId) : -1;

        let theTal =
          tal ? _.findIndex(tal, (t) => Math.abs(t) === subject.accountId) : -1;

        //groupLog(tas);

        if (theTa === -1) {
          tas.push(subject);
          tal.push(subject.accountId);
        } else {
          tas[theTa].participating = true;
          tal[theTal] = subject.accountId;
        }

        const newGroupAccounts = [...prevState.group.groupAccounts];
        const gaIdx = _.findIndex(
          newGroupAccounts,
          (x) => x.accountId === subject.accountId,
        );
        if (gaIdx > -1) newGroupAccounts[gaIdx].isParticipating = false;

        const newState = {
          currentTournament: {
            ...prevState.currentTournament,
            tournamentAccounts: tas,
            tournamentAccountsCount: tas.length,
            tournamentAccountsLookup: tal,
            participants: tal.filter((x) => x > 0).length,
          },
          group: {
            ...prevState.group,
            groupAccounts: newGroupAccounts,
          },
        };

        if (this.context.authState?.account?.id === subject.accountId) {
          newState.currentTournament.amParticipating = true;
          this.props.onDecisionParticipating(this.state.group.id, true);
        }

        return newState;
      } else {
        let newRepeatings = [...prevState.group.repeatingTournaments];
        let newRepeating = { ...newRepeatings[repeatingIdx] };

        if (this.context.authState?.account?.id === subject.accountId)
          newRepeating.amParticipating = true;

        newRepeating.participants = subject.participants;
        newRepeatings[repeatingIdx] = newRepeating;

        let newViewRepeatingGame = prevState.viewingRepeatingGame;
        if (
          this.state.viewingRepeatingId === newRepeating.id &&
          !!newViewRepeatingGame
        )
          newViewRepeatingGame = newRepeating;

        // update their repeating game count
        let gaIdx = _.findIndex(
          prevState.group.groupAccounts,
          (x) => x.accountId === subject.accountId,
        );

        let newGas = prevState.group.groupAccounts;

        if (gaIdx > -1) {
          newGas = [...prevState.group.groupAccounts];
          newGas[gaIdx] = {
            ...newGas[gaIdx],
            repeatingGameCount: newGas[gaIdx].repeatingGameCount + 1,
          };
        }

        return {
          group: {
            ...prevState.group,
            repeatingTournaments: newRepeatings,
            groupAccounts: newGas,
          },
          viewingRepeatingGame: newViewRepeatingGame,
        };
      }
    });
  }

  handleTournamentLeft(subject, remove, callback) {
    if (!subject || !subject.tournamentId) {
      if (callback) callback();
      return;
    }
    this.setState(
      (prevState) => {
        // If no current tournament, likely a repeating one
        let isCurrent = false,
          repeatingIdx = -1;

        if (prevState.currentTournament)
          isCurrent = prevState.currentTournament.id === subject.tournamentId;

        if (!isCurrent && prevState.group.repeatingTournaments)
          repeatingIdx = _.findIndex(
            prevState.group.repeatingTournaments,
            (x) => x.id === subject.tournamentId,
          );

        if (!isCurrent && repeatingIdx === -1) return;

        if (isCurrent) {
          let tas = [...prevState.currentTournament.tournamentAccounts];
          let tal = [...prevState.currentTournament.tournamentAccountsLookup];

          if (!tas) {
            tas = [];
            tal = [];
          }

          let theTa =
            tas ?
              _.findIndex(tas, (t) => t.accountId === subject.accountId)
            : -1;

          let theTal =
            tal ?
              _.findIndex(tal, (t) => Math.abs(t) === subject.accountId)
            : -1;

          if (remove && theTa > -1) {
            tas.splice(theTa, 1);
            tal.splice(theTal, 1);
          } else {
            if (theTa === -1) {
              tas.push(subject);
              tal.push(-subject.accountId);
            } else {
              tas[theTa].participating = false;
              tal[theTal] = -subject.accountId;
            }
          }

          const newGroupAccounts = [...prevState.group.groupAccounts];
          const gaIdx = _.findIndex(
            newGroupAccounts,
            (x) => x.accountId === subject.accountId,
          );
          if (gaIdx > -1) newGroupAccounts[gaIdx].isParticipating = false;

          const newState = {
            currentTournament: {
              ...prevState.currentTournament,
              tournamentAccounts: tas,
              tournamentAccountsCount: tas.length,
              tournamentAccountsLookup: tal,
              participants: tal.filter((x) => x > 0).length,
            },
            group: { ...prevState.group, groupAccounts: newGroupAccounts },
          };

          if (this.context.authState?.account?.id === subject.accountId) {
            newState.currentTournament.amParticipating = false;

            this.props.onDecisionParticipating(this.state.group.id, false);
          }

          return newState;
        } else {
          let newRepeatings = [...prevState.group.repeatingTournaments];
          let newRepeating = { ...newRepeatings[repeatingIdx] };

          if (this.context.authState?.account?.id === subject.accountId)
            newRepeating.amParticipating = false;

          newRepeating.participants = subject.participants;
          newRepeatings[repeatingIdx] = newRepeating;

          let newViewRepeatingGame = prevState.viewingRepeatingGame;
          if (
            this.state.viewingRepeatingId === newRepeating.id &&
            !!newViewRepeatingGame
          )
            newViewRepeatingGame = newRepeating;

          // update their repeating game count
          let gaIdx = _.findIndex(
            prevState.group.groupAccounts,
            (x) => x.accountId === subject.accountId,
          );

          let newGas = prevState.group.groupAccounts;

          if (gaIdx > -1) {
            newGas = [...prevState.group.groupAccounts];
            newGas[gaIdx] = {
              ...newGas[gaIdx],
              repeatingGameCount: Math.max(
                0,
                newGas[gaIdx].repeatingGameCount - 1,
              ),
            };
          }

          return {
            group: {
              ...prevState.group,
              repeatingTournaments: newRepeatings,
              groupAccounts: newGas,
            },
            viewingRepeatingGame: newViewRepeatingGame,
          };
        }
      },
      () => {
        if (callback) callback();
      },
    );
  }

  handleGroupHistoryClick(noRedirect) {
    // Update client state also
    this.setState((prevState) => {
      return {
        groupView: noRedirect ? prevState.groupView : Enum.GroupView.HISTORY,
      };
    });

    this.onUserSlideoutClose();
    this.onTournamentSlideoutClose();

    if (!noRedirect) this.props.history.push(`/g/${this.state.slug}/history`);
  }

  handleModalUserManageOpen(userId) {
    if (!this.state.group) return;

    let user = this.state.group.groupAccounts.find(
      (ga) => ga.accountId === userId,
    );

    if (userId === config.aiAccountMember.accountId) {
      user = config.aiAccountMember;
    }

    this.setState({
      modalUserManageKey: new Date(),
      modalUserManageOpen: true,
      modalUserManageId: user.accountId,
      modalUserManageObj: user,
    });
  }

  handleModalUserManageClose() {
    this.setState({
      modalUserManageOpen: false,
    });
  }

  handleModalTournamentManageOpen() {
    this.setState({
      modalTournamentManageKey: new Date(),
      modalTournamentManageOpen: true,
    });
  }

  handleModalTournamentManageClose() {
    this.setState({
      modalTournamentManageOpen: false,
    });
  }

  handleModalNewGroupOpen(stakeData) {
    this.setState({
      modalNewGroupStake: stakeData,
      modalNewGroupKey: new Date(),
      modalNewGroupOpen: true,
    });
  }

  handleModalNewGroupClose() {
    this.setState({
      modalNewGroupOpen: false,
    });
  }

  handleModalHowToPlayOpen() {
    this.setState({
      modalHowToPlayKey: new Date(),
      modalHowToPlayOpen: true,
    });
  }

  handleModalHowToPlayClose() {
    this.setState({
      modalHowToPlayOpen: false,
    });
  }

  handleModalGroupNameOpen() {
    this.setState({
      modalGroupNameKey: new Date(),
      modalGroupNameOpen: true,
    });
  }

  handleModalGroupNameClose() {
    this.setState({
      modalGroupNameOpen: false,
    });
  }

  handleModalRulesOpen() {
    this.setState({
      modalRulesKey: new Date(),
      modalRulesOpen: true,
    });
  }

  handleModalRulesClose() {
    this.setState({
      modalRulesOpen: false,
    });
  }

  handleModalInviteOpen() {
    this.setState({
      modalInviteKey: new Date(),
      modalInviteOpen: true,
    });
  }

  handleModalInviteClose() {
    this.setState({
      modalInviteOpen: false,
    });
  }

  handleModalDecisionParticipantsOpen() {
    this.setState({
      modalDecisionParticipantsKey: new Date(),
      modalDecisionParticipantsOpen: true,
    });
  }

  handleModalDecisionParticipantsClose() {
    this.setState({
      modalDecisionParticipantsOpen: false,
    });
  }

  handleModalAlertOpen(header, content, focusStakeOnClose) {
    this.setState({
      modalAlertKey: new Date(),
      modalAlertOpen: true,
      modalAlertHeader: header,
      modalAlertContent: content,
      modalAlertFocusStakeOnClose: focusStakeOnClose,
    });
  }

  handleModalAlertClose() {
    this.setState({
      modalAlertOpen: false,
    });

    if (this.state.modalAlertFocusStakeOnClose) {
      if (this.stakeInputRef?.current) this.stakeInputRef.current.focus();
    }
  }

  handleModalDecisionOptionsOpen(gameMode) {
    this.setState((prevState) => {
      return {
        group: {
          ...prevState.group,
          myDraftTournament: {
            ...prevState.group.myDraftTournament,
            gameMode: gameMode,
          },
        },
        modalDecisionOptionsKey: new Date(),
        modalDecisionOptionsOpen: true,
      };
    });
  }

  handleModalDecisionOptionsClose() {
    this.setState({
      modalDecisionOptionsOpen: false,
    });
  }

  handleModalJoinGameOpen() {
    this.setState({
      modalJoinGameKey: new Date(),
      modalJoinGameOpen: true,
    });
  }

  handleModalJoinGameClose() {
    this.setState({
      modalJoinGameOpen: false,
    });
  }

  handleEnableNotifications() {
    this.setState({ notificationsClicked: true });
    this.props.onAskNotificationPermission(true);
  }

  handleConfigChange(config) {
    let startTimeValidity = 0;
    if (config?.startTime) {
      // start time must be in the future but no more than 2 months in the future
      if (config.startTime.isBefore(moment())) {
        startTimeValidity = -1;
      } else if (config.startTime.isAfter(moment().add(2, "months"))) {
        startTimeValidity = 1;
      }
    }

    this.setState(
      (prevState) => {
        return {
          startTimeValidity: startTimeValidity,
          group: {
            ...prevState.group,
            myDraftTournament: {
              ...prevState.group.myDraftTournament,
              ...config,
            },
          },
        };
      },
      () => {},
    );
  }

  handlePrepareClick(config, callback) {
    this.setState({ tournamentPreparing: true });

    let repeatId = this.state.viewingRepeatingId;
    let startTime = this.state.group.myDraftTournament.startTime;
    this.setState({ tempCreatorMoveSet: config?.creatorMoveSet });
    //groupLog("Prepare click. Start time:", startTime);
    if (!repeatId && this.state.groupTab === Enum.TournamentStartMode.QUICK) {
      let startTimeValidity = 0;

      if (startTime.isBefore(moment())) {
        startTimeValidity = -1;
      } else if (startTime.isAfter(moment().add(2, "months"))) {
        startTimeValidity = 1;
      }

      if (startTimeValidity !== 0) {
        this.setState({ startTimeValidity: startTimeValidity });
        return;
      }
    }

    startTime = toHtmlDateTimeFormat(
      moment.isMoment(startTime) ? startTime : moment(startTime),
    );

    if (repeatId) {
      const draft = this.state.group.myDraftTournament;
      Api.rescheduleRepeatingDecision(
        repeatId,
        {
          startTime: startTime,
          startMode: Enum.TournamentStartMode.REPEATING,
          repeatEvery: draft.repeatEvery,
          repeatPeriod: draft.repeatPeriod,
          repeatDaysOfWeek: draft.repeatDaysOfWeek,
          repeatDayOfMonth: draft.repeatDayOfMonth,
          winnersToDraw: draft.winnersToDraw,
        },
        callback,
      );
    } else {
      Api.prepareTournament(
        {
          groupId: this.state.group.id,
          ...this.state.group.myDraftTournament,
          startMode: this.state.groupTab,
          startTime: startTime,
          ...config,
        },
        () => {
          this.setState((prevState) => {
            return {
              tournamentPreparing: false,
              group: {
                ...prevState.group,
                myDraftTournament: this.resetDraftTournament(),
              },
            };
          });
          this.props.onDecisionParticipating(this.state.group.id, true);
          if (callback) callback();
        },
      );
    }
  }

  handleStartClick() {
    let minParticipants = Math.max(
      this.state.currentTournament.winnersToDraw,
      2,
    );

    if (
      this.state.tournamentStarting ||
      this.state.currentTournament?.participants < minParticipants
    ) {
      this.handleModalAlertOpen(
        "Not enough players",
        `This ${gameNoun(this.state.currentTournament)} needs at least ` +
          minParticipants +
          " players to start.",
      );
    } else {
      this.setState({ confirmStartOpen: true });
    }
  }

  handleStartConfirm(event, element, callback) {
    this.setState({ tournamentStarting: true });
    Api.startTournamentEarly(this.state.currentTournament.id, config)
      .catch((e) => alert(e))
      .finally(() => {
        this.setState({ tournamentStarting: false });
        if (callback) callback();
      });

    this.setState({ confirmStartOpen: false });
  }

  handleStartCancel() {
    this.setState({ confirmStartOpen: false });
  }

  handleCancelClick() {
    this.setState({ confirmCancelOpen: true });
  }

  handleCancelConfirm(event, element, callback) {
    const toCancel =
      this.state.viewingRepeatingId ?
        this.state.viewingRepeatingId
      : this.state.currentTournament.id;

    Api.endTournamentEarly(toCancel)
      .catch((e) => alert(e))
      .finally(() => {
        this.handleModalTournamentManageClose();

        if (this.state.viewingRepeatingId) {
          this.setState({
            viewingRepeatingId: null,
            groupView: Enum.GroupView.DEFAULT,
          });
        }

        if (callback) callback();
      });

    this.setState({ confirmCancelOpen: false, modalGroupNameOpen: false });
  }

  handleCancelCancel() {
    this.setState({ confirmCancelOpen: false });
  }

  handlePastDecisionsClick() {
    this.setState({ groupView: Enum.GroupView.HISTORY });
  }

  handleReturnToGroupClick() {
    this.setState({ groupView: Enum.GroupView.DEFAULT });
  }

  handleJoinTournament(tournamentId, targetId, onSuccess, onFailure) {
    Api.rsvpTournament(tournamentId, targetId, true).then((r) => {
      if (r.status === 204 && onSuccess) onSuccess(r);
      else if (onFailure) onFailure(r);
    });
  }

  handleJoinInstantRpsTournament(
    tournamentId,
    targetId,
    moveSet,
    onSuccess,
    onFailure,
  ) {
    Api.rsvpTournament(tournamentId, targetId, true, moveSet).then((r) => {
      if (r.status === 204) {
        this.setState((prevState) => {
          return {
            currentTournament: {
              ...prevState.currentTournament,
              myInstantRpsMoveSet: moveSet,
            },
          };
        });
        onSuccess && onSuccess(r);
      } else if (onFailure) onFailure(r);
    });
  }

  handleLeaveTournament(tournamentId, targetId, onSuccess, onFailure) {
    Api.rsvpTournament(tournamentId, targetId, false).then((r) => {
      if (r.status === 204 && onSuccess) onSuccess(r);
      else if (onFailure) onFailure(r);
    });

    // Api.leaveTournament(tournamentId, targetId).then((r) => {
    //   if (r.status === 204 && onSuccess) onSuccess(r);
    //   else if (onFailure) onFailure(r);
    // });
  }

  handleForfeit(tournamentId, targetId, onSuccess, onFailure) {
    Api.leaveTournament(tournamentId, targetId).then((r) => {
      if (r.status === 204 && onSuccess) onSuccess(r);
      else if (onFailure) onFailure(r);
    });
  }

  handleRpsEventContinue() {
    groupLog("[Event Queue]: Skipping to next event");

    this.props.clearTimeout(this.nextRpsEventTimer);
    this.nextRpsEventTimer = null;

    if (this.state.rpsEventCurrent)
      this.patchStateFromRpsEventPost(this.state.rpsEventCurrent);

    this.props.setTimeout(() => this.processRpsEventQueue(), 1);
  }

  getGroup(callback) {
    var component = this;
    const { slug } = this.state;
    const { authState } = this.context;

    component.props.setCurrentGroup(null, null);

    rpsApi.get(`/group/${slug}`).then((response) => {
      if (!response || component.unmounted) return;

      if (response.status !== 200) {
        component.setState({ groupNotFound: true });

        if (response.status === 401) window.location.href = "/signin";
        else if (authState?.account) component.props.history.push(`/groups`);

        return;
      }

      var group = response.data;
      if (!group) return;

      if (slug !== group.slug) {
        groupLog("Group found but slug mismatch");
        component.props.history.push(`/groups`);
        return;
      }

      if (!group.amPlayer) {
        groupLog("Group found but not player");
        component.props.history.push(`/groups`);
        return;
      }

      component.setState({ groupId: group.id });

      config.strings.decision = "game";
      config.strings.group = "group";

      group.myDraftTournament = this.resetDraftTournament();

      const groupMembersNamesStr = commaSeparateWithNMore(
        group.groupAccounts.map((ga) =>
          ga.accountId === authState?.account?.id ? "You" : ga.accountUserName,
        ),
        3,
        "Your group",
      );
      component.props.setCurrentGroup(group, groupMembersNamesStr);

      //this.sortGroupAccounts(group.groupAccounts);
      var tournamentLoading =
        !!group.myDraftTournamentId || !!group.currentTournamentId;

      component.setState({
        group,
        groupStart: "",
        groupLatestEnd: "",
        tournamentLoading: tournamentLoading,
      });

      callback(group);
    });
  }

  resetDraftTournament(keepStake) {
    //if (!keepStake)  //never keeping stake
    keepStake = "";

    let oneHr = moment().add(1, "hour");

    let h = oneHr.hour();
    let m = oneHr.minute();

    if (m % 5 !== 0) m += 5 - (m % 5);

    if (m === 60) {
      m = 0;
      h += 1;
    }

    oneHr.hour(h);
    oneHr.minute(m);
    oneHr.second(0);
    oneHr.millisecond(0);

    return {
      stake: keepStake,
      stakeIsPositive: null,
      gameMode: Enum.TournamentGameMode.RANDOM,
      startMode: Enum.TournamentStartMode.QUICK,
      prepareLengthMinutes: 60,
      startTime: oneHr,
      winnersToDraw: 1,
      repeatEvery: 1,
      repeatPeriod: Enum.RepeatPeriod.DAILY,
      repeatDaysOfWeek: 127,
      repeatDayOfMonth: oneHr.date(),
      startingPlayers: config.validation.tournamentRps.maxPlayers.default,
      firstToGames: config.validation.tournamentRps.firstToGames.default,
      matchMaxGames: config.validation.tournamentRps.matchMaxGames.default,
      gameLengthMinutes:
        config.validation.tournamentRps.gameLengthMinutes.default,
    };
  }

  joinGroup(groupId, callback) {
    var component = this;

    rpsApi
      .post(`/groupaccount/${groupId}`)
      .then(() => component.getGroup(callback));
  }

  leaveGroup(callback) {
    if (!this.state.group?.id) return;

    rpsApi.delete(`/groupaccount/${this.state.group.id}`).then((response) => {
      if (response.status !== 200) {
        groupLog("Error leaving group", response.data);

        this.handleModalAlertOpen("Error leaving group", response.data);
      }

      if (callback) callback();
    });
  }

  deleteGroup(callback) {
    if (!this.state.group?.id) return;

    var component = this;

    rpsApi.delete(`/group/${this.state.group.id}`).then((response) => {
      if (response.status !== 204) {
        this.handleModalAlertOpen("Error deleting group", response.data);
      } else {
        component.props.history.push(`/groups`);
      }

      if (callback) callback();
    });
  }

  processTournament(tournament) {
    if (!tournament) return null;
    const { authState } = this.context;

    tournament.tournamentAccountsLookup =
      tournament.tournamentAccounts ?
        tournament.tournamentAccounts.map(
          (ta) => ta.accountId * (ta.participating ? 1 : -1),
        )
      : [];

    let myTa =
      authState?.account ?
        _.find(
          tournament.tournamentAccountsLookup,
          (ta) => Math.abs(ta) === authState.account.id,
        )
      : undefined;

    tournament.amParticipating = myTa === undefined ? null : myTa > 0;
    tournament.participants = tournament.tournamentAccountsLookup.filter(
      (x) => x > 0,
    ).length;

    return tournament;
  }

  processGroup(group) {
    var previousTournaments;

    if (group) {
      if (group.startedTournaments && group.startedTournaments.data)
        previousTournaments = group.startedTournaments.data.filter(
          (tournament) => tournament.completed,
        );
    }

    this.setState(
      {
        group,
        previousTournaments,
      },
      () => {
        //this.processGroupAccounts(this.state.group.groupAccounts, callback);
      },
    );
  }

  processGroupAccounts() {
    /*this.setState((prevState) => {
      var now = new Date();
      if (!groupAccounts) groupAccounts = prevState.group.groupAccounts;

      groupAccounts.map(
        (ga) => (ga.isOnline = now - new Date(ga.lastOnline) < 45000)
      );

      return {
        group: {
          ...this.state.group,
          groupAccounts,
        },
      };
    }, callback);*/
  }

  processRpsEvents(rpsEvents) {
    var component = this;
    const { authState } = this.context;

    hubLogGroup("Received RPS events:");
    rpsEvents.map((re) => hubLog(re));
    hubLogGroupEnd();

    this.setState(
      (prevState) => {
        var nextRpsEvents = [...prevState.rpsEventQueue];

        for (var i = 0; i < rpsEvents.length; i++) {
          let arg = null;

          if (rpsEvents[i].typeSlug === "group-closed") arg = "closed";
          else if (
            rpsEvents[i].typeSlug === "account-left" &&
            rpsEvents[i].target.accountId === authState?.account?.id
          )
            arg =
              rpsEvents[i].actor.accountId !== authState?.account?.id ?
                "kicked"
              : "left";

          if (arg) {
            this.returnToGroupsList(arg, this.state.group.name);
            return;
          }

          nextRpsEvents.push({
            event: rpsEvents[i],
          });
        }
        hubLogGroupEnd();
        return {
          rpsEventQueue: nextRpsEvents,
        };
      },
      () => {
        // If the rps event queue is not being processed yet, kick it off
        if (component.nextRpsEventTimer == null) {
          component.processRpsEventQueue();
        }
      },
    );
  }

  processRpsEventQueue() {
    groupLog("[Event Queue]: Processing queue", this.state.rpsEventQueue);
    this.setState(
      (prevState) => {
        if (!prevState.rpsEventQueue || prevState.rpsEventQueue.length === 0) {
          this.props.clearTimeout(this.nextRpsEventTimer);
          this.nextRpsEventTimer = null;

          groupLog("[Event Queue]: No more RPS events to process");

          return { rpsEventCurrent: null };
        }

        const [nextEvent, ...remainingEvents] = prevState.rpsEventQueue;

        //if (nextEvent.event) this.patchStateFromRpsEventPre(nextEvent.event);

        return {
          rpsEventQueue: remainingEvents,
          rpsEventCurrent: nextEvent.event,
        };
      },
      () => {
        if (!this.state.rpsEventCurrent) return;
        this.patchStateFromRpsEventPre(this.state.rpsEventCurrent);

        var eventSpec = config.eventSpecs[this.state.rpsEventCurrent.typeSlug];
        var eventDelay = eventSpec.delay ? eventSpec.delay : 1;

        if (this.state.rpsEventCurrent.typeSlug === "tournament-finished")
          eventDelay = 1;

        this.nextRpsEventTimer = this.props.setTimeout(() => {
          if (this.state.rpsEventCurrent)
            this.patchStateFromRpsEventPost(this.state.rpsEventCurrent);

          this.props.setTimeout(() => this.processRpsEventQueue(), 1);
        }, eventDelay);
      },
    );
  }

  // Specifies exactly how the state should be updated at the start of each event
  patchStateFromRpsEventPre(rpsEvent) {
    const {
      currentTournament,
      currentRound,
      currentMatch,
      currentGame,
      group,
    } = this.state;

    const { authState } = this.context;

    groupLog(
      `[Event Queue]: Pre-patching state for event "${rpsEvent.typeSlug}".`,
      rpsEvent,
    );

    switch (rpsEvent.typeSlug) {
      case "move-made":
        if (
          !currentGame ||
          currentGame.id !== rpsEvent.subject.gameId ||
          rpsEvent.subject.accountId === authState.account.id
        )
          return;

        this.setState((prevState) => {
          const newGame = Game.patchGameNewMoveEvent(
            prevState.currentGame,
            rpsEvent.subject,
          );

          groupLog("Patched game after move-made", newGame);

          return {
            currentGame: newGame,
          };
        });
        break;

      case "game-new-expiry":
        if (!currentGame || currentGame.id !== rpsEvent.subject.id) return;

        // Currently unused as games don't update their expiry
        /*this.setState((prevState) => {
          return {
            currentGame: Game.patchGameNewExpiryEvent(
              prevState.currentGame,
              rpsEvent.subject
            ),
          };
        });*/
        break;

      // case "forfeited-by-player": //
      // case "forfeited-by-host": //

      case "game-finished": //
        if (!currentGame || currentGame.id !== rpsEvent.subject.id) return;
        this.setState(
          (prevState) => {
            return {
              currentGame: Game.patchGameFinishedEvent(
                prevState.currentGame,
                rpsEvent.subject,
              ),
              currentMatch: Match.patchMatchScoreUpdateEvent(
                prevState.currentMatch,
                rpsEvent.subject,
              ),
            };
          },
          () => {
            this.handleMakeMoveClick(true);
          },
        );
        break;

      case "game-started": //
        if (!currentMatch || !currentGame) return;

        this.setState((prevState) => {
          var newGame = Game.nextGame(prevState.currentGame, rpsEvent.subject);
          var newMatch = Match.patchGameStartedEvent(
            currentMatch,
            newGame,
            rpsEvent.subject.newMoves,
          );

          return {
            currentGame: newGame,
            currentMatch: newMatch,
          };
        });

        break;

      case "match-finished": //
        if (!currentMatch || currentMatch.id !== rpsEvent.subject.id) return;
        this.onTournamentSlideoutClose();
        this.onUserSlideoutClose();
        this.setState((prevState) => {
          let patchedMatch = Match.patchMatchFinishedEvent(
            {
              ...prevState.currentMatch,
              ...prevState.currentTournament?.myMatch,
            },
            rpsEvent.subject,
            authState?.account,
            currentTournament.stakeIsPositive,
            currentRound.roundNumber,
          );

          return {
            groupView: Enum.GroupView.TOURNAMENTBOARD,
            currentTournament: {
              ...prevState.currentTournament,
              myMatch: patchedMatch,
            },
            currentMatch: patchedMatch,
            currentGame: null,
          };
        });

        break;

      case "round-finished": //
        if (!currentRound || currentRound.id !== rpsEvent.subject.id) return;

        this.setState((prevState) => {
          let round = Round.patchRoundFinishedEvent(
            prevState.currentRound,
            rpsEvent.subject,
          );

          let rounds = [...prevState.currentTournament.rounds];
          let rIdx = rounds.findIndex((x) => x.id === rpsEvent.subject.id);
          rounds[rIdx] = round;

          return {
            currentTournament: {
              ...prevState.currentTournament,
              rounds: rounds,
            },
            currentRound: round,
          };
        });

        break;

      case "round-progressed": //
        if (!currentRound || currentRound.id !== rpsEvent.subject.id) return;

        // For an individual move, we set "moved" to true
        // For game finish round progress, we should reset the "moved" variable for each player in the update
        // For match finish round progress, we should set the "isCurrentlyPlaying" variable based on the match's result (still in / knocked out)

        if (rpsEvent.subject.matchProgress?.moverId) {
          this.setState((prevState) => {
            const newGroupAccounts = [...prevState.group.groupAccounts];

            const moverId = rpsEvent.subject.matchProgress.moverId;
            const index = newGroupAccounts.findIndex(
              (ga) => ga.accountId === moverId,
            );

            if (index !== -1 && moverId !== config.aiAccountMember.accountId)
              newGroupAccounts[index].hasMoved = true;

            return {
              group: {
                ...prevState.group,
                groupAccounts: newGroupAccounts,
              },
            };
          });
        } else if (
          rpsEvent.subject.matchProgress?.matchAccounts &&
          rpsEvent.subject.matchProgress.matchAccounts.length === 2
        ) {
          // matchAccounts being non-null indicates a match finish
          this.setState((prevState) => {
            const newGroupAccounts = [...prevState.group.groupAccounts];

            const matchAccounts = rpsEvent.subject.matchProgress.matchAccounts;
            const accountId1 = matchAccounts[0].accountId;
            const accountId2 = matchAccounts[1].accountId;

            const index1 = newGroupAccounts.findIndex(
              (ga) => ga.accountId === accountId1,
            );
            const index2 = newGroupAccounts.findIndex(
              (ga) => ga.accountId === accountId2,
            );

            const advanceResult =
              currentTournament.stakeIsPositive ?
                Enum.ResultType.WIN
              : Enum.ResultType.LOSS;

            if (
              index1 !== -1 &&
              accountId1 !== config.aiAccountMember.accountId
            ) {
              newGroupAccounts[index1].hasMoved = false;
              newGroupAccounts[index1].matchComplete = true;
              newGroupAccounts[index1].isCurrentlyPlaying =
                matchAccounts[0].result === advanceResult;
            }
            if (
              index2 !== -1 &&
              accountId2 !== config.aiAccountMember.accountId
            ) {
              newGroupAccounts[index1].hasMoved = false;
              newGroupAccounts[index2].matchComplete = true;
              newGroupAccounts[index2].isCurrentlyPlaying =
                matchAccounts[1].result === advanceResult;
            }

            return {
              group: {
                ...prevState.group,
                groupAccounts: newGroupAccounts,
              },
              currentRound: Round.patchRoundProgressedEvent(
                prevState.currentRound,
                rpsEvent.subject,
              ),
            };
          });
        } else if (rpsEvent.subject.matchProgress?.scores) {
          // matchAccounts being null indicates a game finish

          // For game finish round progress, we should reset the "moved" variable for each player that exists in the scores array.
          // The score array is made up of objects that contain an accountId and a score
          this.setState((prevState) => {
            const [accountId1, accountId2] =
              rpsEvent.subject.matchProgress.scores.map((s) => s.accountId);

            const updatedGroupAccounts = [...prevState.group.groupAccounts];

            const index1 = updatedGroupAccounts.findIndex(
              (ga) => ga.accountId === accountId1,
            );
            const index2 = updatedGroupAccounts.findIndex(
              (ga) => ga.accountId === accountId2,
            );

            if (
              index1 !== -1 &&
              accountId1 !== config.aiAccountMember.accountId
            )
              updatedGroupAccounts[index1].hasMoved = false;
            if (
              index2 !== -1 &&
              accountId2 !== config.aiAccountMember.accountId
            )
              updatedGroupAccounts[index2].hasMoved = false;

            return {
              group: {
                ...prevState.group,
                groupAccounts: updatedGroupAccounts,
              },
            };
          });
        }
        break;

      case "round-started": //
        if (
          !currentRound ||
          !currentTournament ||
          currentTournament.id !== rpsEvent.subject.tournamentId
        )
          return;

        this.handleReceiveStartRound(rpsEvent.subject);
        break;

      // case "retired-by-player": //
      // case "retired-by-host": //

      case "tournament-closed":
        this.handleReceiveCloseTournament(rpsEvent.subject);
        // this.handleModalAlertOpen(
        //   `${firstUpper(config.strings.decision)} ended early`,
        //   `The ${config.strings.decision} was ended by ${rpsEvent.actor.accountUserName} before it was resolved.`
        // );
        break;

      case "tournament-finished": //
        if (!currentTournament || currentTournament.id !== rpsEvent.subject.id)
          return;

        groupLog(currentTournament, rpsEvent.subject);
        this.setState((prevState) => {
          const sortedGas = [...prevState.group.groupAccounts];
          this.sortGroupAccounts(sortedGas, [], false);

          return {
            group: {
              ...prevState.group,
              groupAccounts: sortedGas,
            },
            currentTournament: {
              ...prevState.currentTournament,
              ...rpsEvent.subject,
            },
          };
        });
        //this.refreshDraftTournament();
        break;

      case "tournament-preparing":
        this.handleReceivePreparingTournament(rpsEvent.subject);
        break;

      case "tournament-rescheduled":
        this.handleReceiveRescheduledTournament(rpsEvent.subject);
        break;

      case "tournament-started":
        this.handleReceiveStartTournament(rpsEvent.subject);
        break;

      case "tournament-repeating-rolled-forward":
        this.handleReceiveRepeatingTournamentRolledForward(rpsEvent.subject);
        break;

      case "tournament-joined":
        if (!rpsEvent.subject) break;
        this.handleTournamentJoined(rpsEvent.subject);
        break;

      case "tournament-left":
        if (!rpsEvent.subject) break;
        this.handleTournamentLeft(rpsEvent.subject);
        break;

      case "account-joined": //
        this.handleGroupAccountJoin(rpsEvent.actor);
        break;

      case "account-left": //
        this.handleGroupAccountLeave(
          rpsEvent.target.accountId,
          rpsEvent.subject,
        );
        break;

      case "account-promoted": //
        this.handleGroupAccountUpdate(rpsEvent.subject, true);
        break;

      case "account-demoted": //
        this.handleGroupAccountUpdate(rpsEvent.subject, false);
        break;

      case "account-username-changed": //
        this.handleGroupAccountUserNameUpdate(rpsEvent.subject);
        break;

      case "group-name-changed": //
        this.setState(
          (prevState) => {
            return {
              group: {
                ...prevState.group,
                name: rpsEvent.subject.name,
              },
            };
          },
          () => {
            this.props.onGroupSubscribed(this.state.group);
          },
        );
        break;

      case "group-closed": //
        this.props.onGroupClosed(group.id);
        this.props.history.push(
          `/g/new?closed=${encodeURIComponent(group.name)}`,
          //`/promo`
        );
        break;

      case "group-made-public": //
        window.location.reload(true);
        break;

      default:
        break;
    }
  }

  // Specifies exactly how the state should be updated at the start of each event
  patchStateFromRpsEventPost(rpsEvent) {
    const { currentTournament } = this.state;

    groupLog(
      `[Event Queue]: Post-patching state for event "${rpsEvent.typeSlug}".`,
      rpsEvent,
    );
    switch (rpsEvent.typeSlug) {
      case "tournament-closed":
        this.setState(
          (prevState) => {
            if (rpsEvent.subject.startMode === Enum.TournamentStartMode.QUICK) {
              if (
                !currentTournament ||
                currentTournament.id !== rpsEvent.subject.id
              )
                return {};

              var newState = {
                currentTournament:
                  rpsEvent.subject.deleted ?
                    null
                  : {
                      ...prevState.currentTournament,
                      state: Enum.TournamentState.CANCELLED,
                      startTime: moment(),
                    },
              };

              if (!rpsEvent.subject.deleted) {
                newState.group = {
                  ...prevState.group,
                  startedTournaments: {
                    ...prevState.group.startedTournaments,
                    data:
                      (
                        !prevState.group.startedTournaments ||
                        !prevState.group.startedTournaments.data
                      ) ?
                        [{ ...newState.currentTournament }]
                      : [
                          { ...newState.currentTournament },
                          ...prevState.group.startedTournaments.data,
                        ],
                  },
                };

                newState.currentTournament = null;
              }

              return newState;
            } else {
              const newRepeatingTournaments = [
                ...prevState.group.repeatingTournaments,
              ];

              let repeatingIdx = _.findIndex(
                newRepeatingTournaments,
                (x) => x.id === rpsEvent.subject.id,
              );
              if (repeatingIdx <= -1) return {};
              newRepeatingTournaments.splice(repeatingIdx, 1);

              return {
                group: {
                  ...prevState.group,
                  repeatingTournaments: newRepeatingTournaments,
                },
              };
            }
          },
          () => {
            if (
              !rpsEvent.subject.deleted &&
              rpsEvent.subject.startMode === Enum.TournamentStartMode.QUICK
            ) {
              this.handleGroupHistoryClick(true);
              this.props.history.push(
                `/g/${this.state.slug}/history/${rpsEvent.subject.id}/?direct=true`,
              );
            }
          },
        );
        break;

      case "tournament-finished": {
        // Handled by Board also
        if (!currentTournament || currentTournament.id !== rpsEvent.subject.id)
          return;

        let finishedTournament = rpsEvent.subject;
        const redirectToResult =
          this.state.currentTournament.amParticipating &&
          this.state.groupView === Enum.GroupView.DEFAULT;

        this.setState(
          (prevState) => {
            finishedTournament = {
              ...currentTournament,
              ...finishedTournament,
              amParticipating: prevState.currentTournament.amParticipating,
            };

            let newStarted =
              prevState.group.startedTournaments?.data ?
                [finishedTournament, ...prevState.group.startedTournaments.data]
              : [finishedTournament];

            let newState = {
              currentTournament: null,
              //groupView: Enum.GroupView.HISTORY,
              group: {
                ...prevState.group,
                startedTournaments: {
                  ...(prevState.group.startedTournaments ?
                    prevState.group.startedTournaments
                  : {}),
                  allCount: prevState.group.startedTournaments?.allCount + 1,
                  quickCount:
                    prevState.group.startedTournaments?.quickCount + 1,
                  data: newStarted,
                },
                finishedGameCount: prevState.group.finishedGameCount + 1,
              },
            };

            return newState;
          },
          () => {
            if (redirectToResult) {
              this.props.setTimeout(() => {
                this.handleGroupHistoryClick(true);

                this.props.history.push(
                  `/g/${this.state.slug}/history/${finishedTournament.id}/?direct=true`,
                );
              }, 1);
            }
          },
        );

        break;
      }
      default:
        break;
    }
  }

  refreshDraftTournament() {
    const { group } = this.state;

    this.setState(
      {
        group: {
          ...group,
          myDraftTournament: null,
        },
        tournamentLoading: group.amAssistant || group.amBoss,
      },
      () => {
        if (group.amAssistant || group.amBoss)
          this.props.hub.invoke("RequestDraftTournament", group.id);
      },
    );
  }

  returnToGroupsList(action, name) {
    this.props.history.push(`/groups/?${action}=${encodeURIComponent(name)}`);
    //this.props.history.push(`/promo`);
  }

  handleLoadMoreDecisionHistory(startMode, reset) {
    const { group, loadingDecisionHistory } = this.state;

    if (
      loadingDecisionHistory ||
      !group ||
      !group.startedTournaments ||
      !group.startedTournaments._more ||
      (!group.startedTournaments._more.more && !reset)
    )
      return;

    if (reset)
      this.setState((prevState) => {
        return {
          group: {
            ...prevState.group,
            startedTournaments: {
              allCount: prevState.group.startedTournaments.allCount,
              quickCount: prevState.group.startedTournaments.quickCount,
              repeatingCount: prevState.group.startedTournaments.repeatingCount,
              _more: null,
              data: [],
            },
          },
          loadingDecisionHistory: true,
        };
      });

    const component = this;
    const url =
      reset ?
        `/tournament/?groupId=${group.id}&started=true&perPage=10${
          startMode !== null ? "&startMode=" + startMode : ""
        }`
      : group.startedTournaments._more.more;

    rpsApi.get(url).then((response) => {
      groupLog(response);
      component.setState({ loadingDecisionHistory: false });

      if (response.status !== 200 || !response.data || !response.data.data)
        return;

      component.setState((prevState) => {
        return {
          group: {
            ...prevState.group,
            startedTournaments: {
              allCount: response.data.allCount,
              quickCount: response.data.quickCount,
              repeatingCount: response.data.repeatingCount,
              _more: response.data._more,
              data:
                reset ?
                  [...response.data.data]
                : [
                    ...prevState.group.startedTournaments.data,
                    ...response.data.data,
                  ],
            },
          },
        };
      });
    });
  }

  handleDecisionReplay(oldStake, oldPrepareLengthMinutes, oldWinnersToDraw) {
    if (this.state.currentTournament) {
      this.handleModalAlertOpen(
        "Game already in progress",
        "You cannot replay this game as another game is currently being played.",
      );

      return;
    }

    this.props.history.push(`/g/${this.state.group.slug}`);

    this.props.setTimeout(
      () =>
        this.setState((prevState) => {
          return {
            group: {
              ...prevState.group,
              myDraftTournament: {
                ...prevState.group.myDraftTournament,
                stake: oldStake,
                prepareLengthMinutes: oldPrepareLengthMinutes,
                winnersToDraw: oldWinnersToDraw,
              },
            },
            groupView: Enum.GroupView.CONFIGURE,
          };
        }),
      1,
    );
  }

  handleViewRepeatingTournament(tournamentId) {
    const rt = _.find(
      this.state.group.repeatingTournaments,
      (x) => x.id === tournamentId,
    );
    groupLog("view repeating", tournamentId, rt);

    // if (!this.state.group.amBoss && rt.creatorId !== this.props.account.id)
    //   return;

    this.setState((prevState) => {
      return {
        viewingRepeatingId: tournamentId,
        //viewingRepeatingGame: rt,
        groupView: Enum.GroupView.VIEWREPEATING,
        group: {
          ...prevState.group,
          myDraftTournament: {
            ...rt,
            startTime: moment(rt.startTime),
          },
        },
      };
    });
  }

  handleViewRepeatingTournamentCancel() {
    this.handleTabChange(Enum.GroupTab.REPEATING);
  }

  handleTabChange(tab, toCreate) {
    this.setState((prevState) => {
      let newUnseenRepeatingCount = prevState.group.unseenRepeatingCount;

      if (tab === Enum.GroupTab.REPEATING) {
        Api.groupAccountSeenAllRepeating(
          this.state.group.id,
          this.context.authState?.account?.id,
        );

        this.props.clearUnseenRepeatingForGroup(this.state.group.id);

        newUnseenRepeatingCount = 0;
      }

      return {
        group: {
          ...prevState.group,
          myDraftTournament: this.resetDraftTournament(
            prevState.group.myDraftTournament.stake,
          ),
          //(),
          unseenRepeatingCount: newUnseenRepeatingCount,
        },
        groupTab: tab,
        groupView: toCreate ? Enum.GroupView.CONFIGURE : Enum.GroupView.DEFAULT,
        viewingRepeatingId: null,
      };
    });
  }

  handleSwitchToRepeating() {
    this.handleTabChange(Enum.GroupTab.REPEATING, true);
  }

  newRepeatingDecisionValid() {
    let t = this.state.group?.myDraftTournament;
    if (!t) return false;

    return (
      t.stake &&
      t.repeatEvery > 0 &&
      (t.repeatPeriod === Enum.RepeatPeriod.DAILY ||
        (t.repeatPeriod === Enum.RepeatPeriod.WEEKLY &&
          t.repeatDaysOfWeek > 0) ||
        (t.repeatPeriod === Enum.RepeatPeriod.MONTHLY &&
          t.repeatDayOfMonth >= 1 &&
          t.repeatDayOfMonth <= 31)) &&
      moment.isMoment(t.startTime)
    );
  }

  onUserSlideoutOpen() {
    this.setState({ userSlideOutOpen: true, lastSlideOutOpened: "user" });
  }

  onUserSlideoutClose() {
    this.setState({ userSlideOutOpen: false });
  }

  onTournamentSlideoutOpen() {
    this.setState({
      tournamentSlideOutOpen: true,
      lastSlideOutOpened: "tournament",
    });
  }

  onTournamentSlideoutClose() {
    this.setState({ tournamentSlideOutOpen: false });
  }

  handleTouchStart() {
    return;
  }

  handleTouchEnd() {
    return;
  }

  handleTouchMove() {
    return;
  }

  handleNewRepeatingGame() {
    this.setState((prevState) => {
      return {
        viewingRepeatingId: null,
        groupView: Enum.GroupView.CONFIGURE,
        group: {
          ...prevState.group,
          myDraftTournament: this.resetDraftTournament(
            prevState.group.myDraftTournament.stake,
          ),
        },
      };
    });
  }

  handlePrepareClickRepeating(tournament) {
    this.handlePrepareClick(tournament, () => {
      this.setState((prevState) => {
        return {
          group: {
            ...prevState.group,
            myDraftTournament: this.resetDraftTournament(),
          },
          groupView: Enum.GroupView.DEFAULT,
          groupTab: Enum.GroupTab.REPEATING,
          viewingRepeatingId: null,
          viewingRepeatingGame: null,
        };
      });
    });
  }

  handleLeaveTournamentViewing() {
    this.handleLeaveTournament(this.state.viewingRepeatingId, null, () => {
      this.setState((prevState) => {
        return {
          viewingRepeatingGame: {
            ...prevState.viewingRepeatingGame,
            amParticipating: false,
          },
        };
      });
    });
  }

  handleJoinTournamentViewing() {
    this.handleJoinTournament(this.state.viewingRepeatingId, null, () => {
      this.setState((prevState) => {
        return {
          viewingRepeatingGame: {
            ...prevState.viewingRepeatingGame,
            amParticipating: true,
          },
        };
      });
    });
  }

  handleMakeMoveClick() {
    this.onTournamentSlideoutClose();
    this.onUserSlideoutClose();
    this.setState(
      () => {
        return {
          groupView: Enum.GroupView.TOURNAMENTBOARD,
        };
      },
      () => {
        this.props.history.push(`/g/${this.state.slug}/play`);
      },
    );
  }

  handleCloseBoard() {
    this.setState(
      () => {
        return {
          groupView: Enum.GroupView.DEFAULT,
        };
      },
      () => {
        this.props.history.push(`/g/${this.state.slug}`);
      },
    );
  }

  handleResumeSolo() {
    this.setState(() => {
      return {
        groupView: Enum.GroupView.TOURNAMENTBOARD,
      };
    });
  }

  render() {
    const {
      group,
      slug,
      groupLoading,
      groupView,
      currentTournament,
      tournamentStarting,
      tournamentPreparing,
      groupTab,
      viewingRepeatingId,
      justCreated,
      loadingDecisionHistory,
      viewingRepeatingGame,
      userSlideOutOpen,
      userSlideOutTranslateX,
      userSlideOutDragging,
      tournamentSlideOutOpen,
      tournamentSlideOutTranslateX,
      tournamentSlideOutDragging,
      startTimeValidity,
      confirmStartOpen,
      notificationsClicked,
      lastSlideOutOpened,
      tournamentStatusIcon,
      tournamentStatusText,
    } = this.state;

    const { history, pathTournamentId } = this.props;

    return (
      <>
        {/* <div
          style={{
            position: "absolute",
            zIndex: "999999",
            backgroundColor: "rgba(0,0,0,0.8)",
            color: "white",
            padding: "0px 15px",
          }}
        >
          <pre>
            isSolo: {isSolo ? "true" : "false"}
            <br />
            groupView: {groupView}
            <br />
          </pre>
        </div> */}
        <>
          {lastSlideOutOpened === "tournament" && (
            <TournamentSlideout
              group={group}
              tournament={currentTournament}
              round={this.state.currentRound}
              handleTouchStart={this.handleTouchStart}
              handleTouchEnd={this.handleTouchEnd}
              handleTouchMove={this.handleTouchMove}
              handleUserClick={this.handleModalUserManageOpen}
              handleModalRulesOpen={this.handleModalRulesOpen}
            ></TournamentSlideout>
          )}
          {lastSlideOutOpened === "user" && (
            <UserSlideout
              groupLoading={groupLoading}
              group={group}
              currentTournament={currentTournament}
              onUserSlideoutOpen={this.onUserSlideoutOpen}
              handleModalInviteOpen={this.handleModalInviteOpen}
              handleTouchStart={this.handleTouchStart}
              handleTouchEnd={this.handleTouchEnd}
              handleTouchMove={this.handleTouchMove}
              tournamentStatusText={tournamentStatusText}
              tournamentStatusIcon={tournamentStatusIcon}
            ></UserSlideout>
          )}
          <div
            style={{
              transform: `translateX(calc(${
                userSlideOutOpen ? "-83%"
                : tournamentSlideOutOpen ? "83%"
                : "0%"
              } + ${userSlideOutTranslateX}px + ${tournamentSlideOutTranslateX}px))`,
            }}
            className={`subpage subpage-group ${
              userSlideOutOpen || tournamentSlideOutOpen ? "slideout-open" : ""
            } ${
              userSlideOutDragging || tournamentSlideOutDragging ? "" : (
                "slideout-not-dragging"
              )
            }`}
            id="subpage-create"
            onTouchStart={this.handleTouchStart}
            onTouchEnd={this.handleTouchEnd}
            onTouchMove={this.handleTouchMove}
          >
            <div
              className="slideout-close-overlay"
              onClick={() => {
                this.onUserSlideoutClose();
                this.onTournamentSlideoutClose();
              }}
            ></div>
            {groupLoading && (
              <Dimmer inverted active>
                <Loader inverted active content="Loading..." />
              </Dimmer>
            )}
            {!groupLoading && (
              <div id="subpage-content">
                <div className="inner-content">
                  {group && (
                    <>
                      {groupView === Enum.GroupView.HISTORY && (
                        <DecisionHistory
                          slug={slug}
                          group={group}
                          tournaments={group.startedTournaments}
                          historyTournamentId={pathTournamentId}
                          onLoadMore={this.handleLoadMoreDecisionHistory}
                          history={history}
                          onDecisionReplay={this.handleDecisionReplay}
                          loadingDecisionHistory={loadingDecisionHistory}
                          handleModalAlertOpen={this.handleModalAlertOpen}
                        ></DecisionHistory>
                      )}
                      {groupView === Enum.GroupView.TOURNAMENTBOARD && (
                        // <GroupTournamentBoard
                        //   currentTournament={currentTournament}
                        //   currentMatch={this.state.currentMatch}
                        //   currentGame={this.state.currentGame}
                        //   handleMakeMove={this.handleMoveClick}
                        // ></GroupTournamentBoard>

                        <DecidingInterface
                          group={group}
                          currentTournament={currentTournament}
                          currentRound={this.state.currentRound}
                          currentMatch={this.state.currentMatch}
                          currentGame={this.state.currentGame}
                          rpsEventCurrent={this.state.rpsEventCurrent}
                          onMove={this.handleMoveClick}
                          onRpsEventContinue={this.handleRpsEventContinue}
                          onCloseBoard={this.handleCloseBoard}
                          handleModalUserManageOpen={
                            this.handleModalUserManageOpen
                          }
                          handleModalInstallAppEnableNotificationsOpen={
                            this.handleModalInstallAppEnableNotificationsOpen
                          }
                          handleModalAlertOpen={this.handleModalAlertOpen}
                        />
                      )}
                      {groupView !== Enum.GroupView.HISTORY &&
                        groupView !== Enum.GroupView.TOURNAMENTBOARD && (
                          <>
                            {(!currentTournament ||
                              (currentTournament.state !==
                                Enum.TournamentState.CANCELLED &&
                                currentTournament.state !==
                                  Enum.TournamentState.COMPLETE)) && (
                              <GroupUpper
                                group={group}
                                groupTab={groupTab}
                                onUserSlideoutOpen={this.onUserSlideoutOpen}
                                userSlideOutOpen={userSlideOutOpen}
                                onTournamentSlideoutOpen={
                                  this.onTournamentSlideoutOpen
                                }
                                handleGroupHistoryClick={
                                  this.handleGroupHistoryClick
                                }
                                handleModalGroupNameOpen={
                                  this.handleModalGroupNameOpen
                                }
                                handleTabChange={this.handleTabChange}
                                currentTournament={currentTournament}
                                currentRound={this.state.currentRound}
                                tournamentStatusText={tournamentStatusText}
                                tournamentStatusIcon={tournamentStatusIcon}
                                displayNewMemberTag={
                                  this.state.displayNewMemberTag
                                }
                              />
                            )}

                            {groupTab === Enum.GroupTab.REPEATING && (
                              <GroupLowerRepeating
                                groupView={groupView}
                                group={group}
                                tournamentPreparing={tournamentPreparing}
                                viewingRepeatingId={viewingRepeatingId}
                                viewingRepeatingGame={viewingRepeatingGame}
                                newRepeatingDecisionValid={
                                  this.newRepeatingDecisionValid
                                }
                                handleViewRepeatingTournament={
                                  this.handleViewRepeatingTournament
                                }
                                handleConfigChange={this.handleConfigChange}
                                handleModalDecisionParticipantsOpen={
                                  this.handleModalDecisionParticipantsOpen
                                }
                                handleViewRepeatingTournamentCancel={
                                  this.handleViewRepeatingTournamentCancel
                                }
                                handleCancelClick={this.handleCancelClick}
                                handleNewRepeatingGame={
                                  this.handleNewRepeatingGame
                                }
                                handleConfigClick={() => {
                                  this.setState({
                                    groupView: Enum.GroupView.CONFIGURE,
                                  });
                                }}
                                handleConfigCancel={() => {
                                  this.setState({
                                    groupView: Enum.GroupView.VIEWREPEATING,
                                  });
                                }}
                                handlePrepareClick={
                                  this.handlePrepareClickRepeating
                                }
                                handleLeaveTournamentViewing={
                                  this.handleLeaveTournamentViewing
                                }
                                handleJoinTournamentViewing={
                                  this.handleJoinTournamentViewing
                                }
                                handleModalInstallAppEnableNotificationsOpen={
                                  this
                                    .handleModalInstallAppEnableNotificationsOpen
                                }
                                handleModalAlertOpen={this.handleModalAlertOpen}
                                handleJoyrideTourOpen={() =>
                                  this.startJoyrideTour(
                                    "groupRepeatingSetupTour",
                                  )
                                }
                              />
                            )}

                            {currentTournament && (
                              <>
                                {groupTab === Enum.GroupTab.QUICK &&
                                  currentTournament.state ===
                                    Enum.TournamentState.PREPARE && (
                                    <GroupLowerQuick
                                      group={group}
                                      currentTournament={currentTournament}
                                      tempCreatorMoveSet={
                                        this.state.tempCreatorMoveSet
                                      }
                                      tournamentStarting={tournamentStarting}
                                      handleModalInviteOpen={
                                        this.handleModalInviteOpen
                                      }
                                      handleEnableNotifications={
                                        this.handleEnableNotifications
                                      }
                                      handleJoinTournament={
                                        this.handleJoinTournament
                                      }
                                      handleLeaveTournament={
                                        this.handleLeaveTournament
                                      }
                                      handleJoinInstantRpsTournament={
                                        this.handleModalJoinGameOpen
                                      }
                                      handleStartClick={this.handleStartClick}
                                      confirmStartOpen={confirmStartOpen}
                                      handleStartCancel={this.handleStartCancel}
                                      handleStartConfirm={
                                        this.handleStartConfirm
                                      }
                                      handleModalUserManageOpen={
                                        this.handleModalUserManageOpen
                                      }
                                      handleRandomGameDrawTransitionEnd={
                                        this.handleRandomGameDrawTransitionEnd
                                      }
                                      randomGameDrawTransitionShow={
                                        this.state.randomGameDrawTransitionShow
                                      }
                                      handleJoyrideTourOpen={() =>
                                        this.startJoyrideTour(
                                          "groupAwaitingPlayersTour",
                                        )
                                      }
                                    />
                                  )}

                                {groupTab === Enum.GroupTab.QUICK &&
                                  currentTournament.state ===
                                    Enum.TournamentState.ACTIVE && (
                                    <GroupLowerRpsTournament
                                      group={group}
                                      currentTournament={currentTournament}
                                      currentRound={this.state.currentRound}
                                      currentMatch={this.state.currentMatch}
                                      currentGame={this.state.currentGame}
                                      handleMakeMoveClick={
                                        this.handleMakeMoveClick
                                      }
                                      handleModalUserManageOpen={
                                        this.handleModalUserManageOpen
                                      }
                                      handleJoyrideTourOpen={() =>
                                        this.startJoyrideTour(
                                          "groupRpsCurrentlyPlayingTour",
                                        )
                                      }
                                      handleModalAlertOpen={
                                        this.handleModalAlertOpen
                                      }
                                    />
                                  )}

                                {currentTournament.state ===
                                  Enum.TournamentState.COMPLETE && (
                                  <DecisionResults
                                    group={group}
                                    tournament={currentTournament}
                                    onDecisionReplay={this.handleDecisionReplay}
                                    handleModalAlertOpen={
                                      this.handleModalAlertOpen
                                    }
                                  />
                                )}
                                {groupView !== Enum.GroupView.HISTORY &&
                                  currentTournament.state ===
                                    Enum.TournamentState.CANCELLED && (
                                    <DecisionResults
                                      group={group}
                                      tournament={currentTournament}
                                      onDecisionReplay={
                                        this.handleDecisionReplay
                                      }
                                      handleModalAlertOpen={
                                        this.handleModalAlertOpen
                                      }
                                    />
                                  )}
                              </>
                            )}

                            {!currentTournament &&
                              groupTab === Enum.GroupTab.QUICK && (
                                <StakeInputRefContext.Provider
                                  value={{
                                    setStakeInputRef: this.setStakeInputRef,
                                  }}
                                >
                                  <GroupLowerNoTournament
                                    justCreated={justCreated}
                                    group={group}
                                    startTimeValidity={startTimeValidity}
                                    notificationsClicked={notificationsClicked}
                                    setNotificationsClicked={(val) => {
                                      this.setState({
                                        notificationsClicked: val,
                                      });
                                    }}
                                    handleConfigChange={this.handleConfigChange}
                                    tournamentPreparing={tournamentPreparing}
                                    handleEnableNotifications={
                                      this.handleEnableNotifications
                                    }
                                    handleSetUpQuickGameClick={() =>
                                      this.setState({
                                        groupView: Enum.GroupView.CONFIGURE,
                                        justCreated: false,
                                      })
                                    }
                                    handleModalDecisionOptionsOpen={
                                      this.handleModalDecisionOptionsOpen
                                    }
                                    handleModalAlertOpen={
                                      this.handleModalAlertOpen
                                    }
                                    handleJoyrideTourOpen={() =>
                                      this.startJoyrideTour(
                                        "groupQuickSetupTour",
                                      )
                                    }
                                  />
                                </StakeInputRefContext.Provider>
                              )}

                            <ConfirmBox
                              content={
                                <div className="content">
                                  <span className="text-red">
                                    {this.state.viewingRepeatingId && (
                                      <>
                                        Deleting a repeating game cannot be
                                        undone.
                                        <br />
                                        Are you sure you want to delete it?
                                      </>
                                    )}
                                    {!this.state.viewingRepeatingId && (
                                      <>
                                        <b>This action cannot be undone.</b>
                                      </>
                                    )}
                                  </span>
                                </div>
                              }
                              header={`Cancel ${gameNoun(currentTournament)}?`}
                              cancelButton="No, keep it"
                              confirmButton={`Yes, ${
                                this.state.viewingRepeatingId ?
                                  "delete"
                                : "cancel"
                              } it`}
                              open={this.state.confirmCancelOpen}
                              onCancel={this.handleCancelCancel}
                              onConfirm={this.handleCancelConfirm}
                            ></ConfirmBox>
                          </>
                        )}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>

          <Joyride
            key={this.state.joyrideKey}
            steps={joyrideTours[this.state.joyrideTourKey] || []}
            callback={this.callbackJoyrideTour}
            run={this.state.joyrideTourRun}
            hideCloseButton={true}
            continuous={true}
            locale={{
              back: "Back",
              close: "Close",
              last: "Finish",
              next: "Next",
              skip: "Skip",
            }}
            styles={{
              options: {
                primaryColor: "#2185d0",
              },
              buttonNext: {
                borderRadius: 0,
                fontWeight: "bold",
                fontSize: "18px",
                padding: "10px 20px",
              },
              buttonBack: {
                fontWeight: "bold",
                fontSize: "16px",
                padding: "10px 20px",
              },
              tooltip: {
                borderRadius: 0,
                padding: "10px 10px",
              },
              tooltipContent: {
                padding: "10px 5px",
              },
            }}
          />

          {this.context.authState?.isRkoAdmin &&
            groupView === Enum.GroupView.DEFAULT &&
            currentTournament && (
              <DevTools
                tournamentId={currentTournament.id}
                hub={this.props.hub}
              />
            )}

          <ModalUserManage
            key={this.state.modalUserManageKey}
            open={this.state.modalUserManageOpen}
            close={this.handleModalUserManageClose}
            group={group}
            userId={this.state.modalUserManageId}
            user={this.state.modalUserManageObj}
            currentTournament={currentTournament}
            hideParticipating={true}
          />

          <ModalGroupOptions
            key={"group-name-" + this.state.modalGroupNameKey}
            open={this.state.modalGroupNameOpen}
            close={this.handleModalGroupNameClose}
            group={group}
            currentTournament={currentTournament}
            onLeaveGroup={this.leaveGroup}
            onModalAlertOpen={this.handleModalAlertOpen}
            onSaveNameSuccess={(name) => {
              this.setState(
                (prevState) => {
                  return {
                    group: {
                      ...prevState.group,
                      name,
                    },
                  };
                },
                () => {
                  this.props.onGroupSubscribed(this.state.group);
                },
              );
            }}
            onDeleteGroup={this.deleteGroup}
            tournament={currentTournament}
            onCancelClick={this.handleCancelClick}
          />

          {viewingRepeatingGame && (
            <ModalDecisionParticipants
              key={
                "decision-participants-" +
                this.state.modalDecisionParticipantsKey
              }
              open={this.state.modalDecisionParticipantsOpen}
              close={this.handleModalDecisionParticipantsClose}
              group={group}
              tournament={viewingRepeatingGame}
            />
          )}

          {group && (
            <ModalDecisionOptions
              key={"decision-options-" + this.state.modalDecisionOptionsKey}
              open={this.state.modalDecisionOptionsOpen}
              close={this.handleModalDecisionOptionsClose}
              group={group}
              tournament={group.myDraftTournament}
              onTournamentPrepare={this.handlePrepareClick}
              handleModalAlertOpen={this.handleModalAlertOpen}
              handleModalRulesOpen={this.handleModalRulesOpen}
            />
          )}

          <ModalInvite
            key={"invite-" + this.state.modalInviteKey}
            open={this.state.modalInviteOpen}
            close={this.handleModalInviteClose}
            group={group}
            tournament={currentTournament}
          />

          <ModalRules
            key={"rules-" + this.state.modalRulesKey}
            open={this.state.modalRulesOpen}
            close={this.handleModalRulesClose}
          />

          <ModalGroupAlert
            key={"group-alert-" + this.state.modalAlertKey}
            open={this.state.modalAlertOpen}
            close={this.handleModalAlertClose}
            header={this.state.modalAlertHeader}
            content={this.state.modalAlertContent}
          />
        </>
      </>
    );
  }
}

export default withAuth(ReactTimeout(GroupPage));
