import React, { useContext } from "react";
import { Form } from "semantic-ui-react";

import { config } from "../../../config";
import { StakeInputRefContext } from "../../../contexts/StakeInputRefContext";
//import Icon from "../../Icon/Icon";
//import ModalStakeSuggested from "../../Modal/ModalStakeSuggested/ModalStakeSuggested";

import "./StakeInput.scss";

const StakeInput = ({ stake, onConfigChange }) => {
  const { setStakeInputRef } = useContext(StakeInputRefContext);

  // const [modalStakeSuggestedOpen, setModalStakeSuggestedOpen] = useState(false);
  // const [modalStakeSuggestedKey, setModalStakeSuggestedKey] = useState(
  //   new Date(),
  // );

  // const handleModalStakeSuggestedOpen = () => {
  //   setModalStakeSuggestedOpen(true);
  //   setModalStakeSuggestedKey(new Date());
  // };

  // const handleModalStakeSuggestedClose = () => {
  //   setModalStakeSuggestedOpen(false);
  // };

  // const onSuggestedStakeClick = (stake) => {
  //   onConfigChange({ stake });
  //   handleModalStakeSuggestedClose();
  // };

  return (
    <div className={`stake-input`}>
      <Form.Field className="tournament-config-stake">
        <div className="stake-wrapper">
          <input
            id="tournament-config-stake-input"
            type="text"
            name="stake"
            value={stake}
            onChange={(ev) => {
              onConfigChange({
                stake: ev.target.value.substring(
                  0,
                  config.validation.maxLength.stake,
                ),
              });
            }}
            placeholder="Pick tonight's movie, wash up, etc."
            autoComplete="off"
            maxLength={30}
            ref={setStakeInputRef}
          />
          {/* <div className="suggestions-btn-wrapper">
            <Icon
              className="fas fa-lightbulb"
              onClick={handleModalStakeSuggestedOpen}
            ></Icon>
          </div> */}
          {/* <EmojiPicker onAddEmoji={handleAddEmoji} direction="down" /> */}
        </div>
      </Form.Field>

      {/* <ModalStakeSuggested
        key={modalStakeSuggestedKey}
        open={modalStakeSuggestedOpen}
        close={handleModalStakeSuggestedClose}
        onClickStake={onSuggestedStakeClick}
      ></ModalStakeSuggested> */}
    </div>
  );
};
export default StakeInput;
