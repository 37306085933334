import React from "react";
import { Route, Switch } from "react-router-dom";

import ExternalRedirect from "../../components/ExternalRedirect/ExternalRedirect";
import AuthContext from "../../contexts/AuthContext";
import { GSLUG_PARAM, GSLUG_REGEX } from "../../utilities";
import CreatePage from "../CreatePage/CreatePage";
import FaqPage from "../FaqPage/FaqPage";
import GroupPage from "../GroupPage/GroupPage";
import GroupsPage from "../GroupsPage/GroupsPage";
import NotificationsPage from "../NotificationsPage/NotificationsPage";
import SettingsPage from "../SettingsPage/SettingsPage";
import AboutPage from "../StaticPages/AboutPage";

import "./LoggedInMaster.scss";

const LoggedInMaster = (props) => {
  const {
    hub,
    registerHubStateListener,
    clearHubStateListeners,
    slug,
    pathIsCancelled,
    pathIsHistory,
    pathIsResults,
    pathIsPlay,
    pathTournamentId,
    groups,
    groupsLoaded,
    currentGroup,
    onGroupClosed,
    onGroupSubscribed,
    setCurrentGroup,
    onDecisionParticipating,
    clearUnseenRepeatingForGroup,
    onLoadMoreGroups,
    onModalRulesOpen,
    onChangeUserName,
    onChangeTagline,
    notificationsLoaded,
    loadAccountNotifications,
    clearAccountNotifications,
  } = props;

  const { authState } = React.useContext(AuthContext);

  return (
    <div className="page" id="page-logged-in">
      <div id="page-content">
        <Switch>
          <Route
            exact
            path="/about"
            render={(props) =>
              authState?.isAuthenticated ?
                <AboutPage {...props} />
              : <ExternalRedirect to={`/signin/?return=/about`} />
            }
          />

          <Route
            exact
            path="/help"
            render={(props) =>
              authState?.isAuthenticated ?
                <FaqPage {...props} onModalRulesOpen={onModalRulesOpen} />
              : <ExternalRedirect to={`/signin/?return=/help`} />
            }
          />

          <Route
            exact
            path="/notifications"
            render={(props) =>
              authState?.isAuthenticated ?
                <NotificationsPage
                  notificationsLoaded={notificationsLoaded}
                  loadAccountNotifications={loadAccountNotifications}
                  clearAccountNotifications={clearAccountNotifications}
                  {...props}
                />
              : <ExternalRedirect to={`/signin/?return=/notifications`} />
            }
          />

          <Route
            exact
            path="/settings"
            render={(props) =>
              authState?.isAuthenticated ?
                <SettingsPage
                  onChangeUserName={onChangeUserName}
                  onChangeTagline={onChangeTagline}
                  {...props}
                />
              : <ExternalRedirect to={`/signin/?return=/settings`} />
            }
          />

          <Route
            exact
            path="/groups"
            render={(props) =>
              authState?.isAuthenticated ?
                <GroupsPage
                  groups={groups}
                  groupsLoaded={groupsLoaded}
                  onLoadMoreGroups={onLoadMoreGroups}
                  {...props}
                />
              : <ExternalRedirect to={`/signin`} />
            }
          />

          <Route
            exact
            path="/g/new"
            render={(props) =>
              authState?.isAuthenticated ?
                <CreatePage {...props} />
              : <ExternalRedirect to={`/signin`} />
            }
          />

          <Route
            exact
            path={[
              `/g/${GSLUG_PARAM}${GSLUG_REGEX}/:history(history)?/:id([0-9]+)?`,
              `/g/${GSLUG_PARAM}${GSLUG_REGEX}/:cancelled(cancelled)?`,
              `/g/${GSLUG_PARAM}${GSLUG_REGEX}/:results(results)?`,
              `/g/${GSLUG_PARAM}${GSLUG_REGEX}/:play(play)?`,
            ]}
            render={(props) =>
              authState?.isAuthenticated ?
                <GroupPage
                  hub={hub}
                  registerHubStateListener={registerHubStateListener}
                  clearHubStateListeners={clearHubStateListeners}
                  currentGroup={currentGroup}
                  onGroupClosed={onGroupClosed}
                  onGroupSubscribed={onGroupSubscribed}
                  key={slug}
                  slug={slug}
                  pathIsCancelled={pathIsCancelled}
                  pathIsHistory={pathIsHistory}
                  pathIsResults={pathIsResults}
                  pathIsPlay={pathIsPlay}
                  pathTournamentId={pathTournamentId}
                  setCurrentGroup={setCurrentGroup}
                  onDecisionParticipating={onDecisionParticipating}
                  clearUnseenRepeatingForGroup={clearUnseenRepeatingForGroup}
                  {...props}
                />
              : <ExternalRedirect
                  to={`/signin/?return=g/${slug}${
                    pathIsHistory ? "/history" : ""
                  }${pathTournamentId ? "/" + pathTournamentId : ""}`}
                />
            }
          />
        </Switch>
      </div>
    </div>
  );
};

export default LoggedInMaster;
