import React from "react";

import Timer from "./Timer/Timer";

import "./InfoPanel.scss";
import TournamentRulesSummary from "../../TournamentRulesSummary/TournamentRulesSummary";

const InfoPanel = ({
  group,
  endTime,
  moved,
  hide,
  currentTournament,
  currentRound,
}) => {
  return (
    <div
      className={`component-info-panel ${
        group?.isSolo ? "info-panel-solo" : ""
      }`}
    >
      {!hide && (
        <div className="side-info">
          {currentTournament && (
            <TournamentRulesSummary
              firstToGames={currentTournament.firstToGames}
              stakeIsPositive={
                group?.isSolo ? true : currentTournament.stakeIsPositive
              }
              gameLengthMinutes={
                group?.isSolo ? null : currentTournament.gameLengthMinutes
              }
              winnersChosen={
                group?.isSolo ? null : currentTournament.winnersToDraw
              }
              isFinalRound={currentRound?.isFinalRound}
            />
          )}
          {!group?.isSolo && (
            <Timer
              endTime={endTime}
              labelled={true}
              desc={moved ? "for opponent to move" : "to make move"}
            ></Timer>
          )}
        </div>
      )}
    </div>
  );
};

export default InfoPanel;
